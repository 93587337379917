import { types, successAction, mergeEntities } from "@authzsvc/api-lib";
import * as at from "../actions/types";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case successAction(types.GET_GROUP_MEMBER_GROUPS):
      const stateCopy = [...state];
      return mergeEntities([{ data: stateCopy }, action.payload]);
    case at.CLEAR_GROUPS_DETAILS:
      return [];
    case at.CLEAR_GROUP_GROUPS:
      return [];
    default:
      return state;
  }
};
