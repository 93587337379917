import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { CernToolbar } from "@authzsvc/common-react-components";
import { keycloakAuthenticated } from "../../selectors";
import GroupMembershipsButton from "./GroupMembershipsButton";
import { logout } from "../../actions/api";
import HeaderDropdownItems from "./HeaderDropdownItems";

const HeaderBarGroupsPortal = ({
  authenticated,
  keycloak,
  userToken,
  logout,
}) => {
  const createDropdownMenuItems = () => {
    return <HeaderDropdownItems />;
  };

  return (
    <>
      <CernToolbar
        extraLogoutFct={logout}
        authenticated={authenticated}
        keycloak={keycloak}
        userToken={userToken}
        dropDownItems={createDropdownMenuItems()}
      >
        <GroupMembershipsButton />
      </CernToolbar>
    </>
  );
};

export default withRouter(
  connect(
    (state) => ({
      authenticated: keycloakAuthenticated(state),
      keycloak: state.keycloak.instance,
      userToken: state.keycloak.userToken,
    }),
    {
      logout,
    }
  )(HeaderBarGroupsPortal)
);
