import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Segment,
  Header,
  Button,
  Icon,
  Label,
  List,
  Input,
  Modal,
} from "semantic-ui-react";
import { ContentTable } from "@authzsvc/common-react-components";
import { validateEmail } from "../../../util";

const MailAliases = ({
  primaryMailAlias,
  mailAliases,
  canModifyGroup,
  updateGroupMailProperties,
}) => {
  const [emailInputError, setEmailInputError] = useState("");
  const [isEmailInputTouched, setIsEmailInputTouched] = useState(false);
  const [emailInputKey, setEmailInputKey] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isRemoving, setIsRemoving] = useState(false);
  const cernEmailDomain = "@cern.ch";
  const hasSelections = selectedRows.length > 0;
  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (selectedRowKeys) => {
      setSelectedRows(selectedRowKeys);
    },
  };
  const tableColumns = [
    { title: "Email Alias", dataIndex: "alias", key: "alias" },
  ];
  const dataSource =
    mailAliases?.map((alias, i) => ({
      key: i,
      alias: alias,
    })) || [];

  const getRowKey = (mailAlias) => {
    return mailAlias?.alias;
  };

  const validateEmailInput = (mailAliasLocal, dataToCompare) => {
    const mailAliasLocalWithDomain = mailAliasLocal + cernEmailDomain;
    if (!mailAliasLocal) {
      return "Email is required";
    } else if (!validateEmail(mailAliasLocalWithDomain)) {
      return "Please enter a valid email";
    } else if (
      dataToCompare?.includes(mailAliasLocalWithDomain) ||
      mailAliasLocalWithDomain === primaryMailAlias
    ) {
      return "This email alias already exists";
    }
    return "";
  };

  const handleEmailInputChange = (e) => {
    setInputValue(e.target.value);
    if (isEmailInputTouched) {
      setEmailInputError(validateEmailInput(e.target.value, mailAliases));
    }
  };

  const handleEmailInputBlur = (e) => {
    setIsEmailInputTouched(true);
    setEmailInputError(validateEmailInput(e.target.value, mailAliases));
  };

  const handleAdd = () => {
    setIsEmailInputTouched(false);
    setEmailInputKey((prevKey) => prevKey + 1);
    setInputValue("");
    setEmailInputError("");
    setIsAddModalOpen(true);
  };

  const handleAddModalOk = () => {
    if (!validateEmailInput(inputValue)) {
      const updatedMailAliases = [...mailAliases, inputValue + cernEmailDomain];
      updateGroupMailProperties({ mailAliases: updatedMailAliases });
      setIsAddModalOpen(false);
    }
  };

  const handleAddModalCancel = () => {
    setIsAddModalOpen(false);
  };

  const handleRemove = () => {
    setIsRemoveModalOpen(true);
  };

  const handleRemoveModalOk = () => {
    setIsRemoving(true);
    const updatedMailAliases = mailAliases?.filter(
      (alias) => !selectedRows.includes(alias)
    );
    updateGroupMailProperties({ mailAliases: updatedMailAliases });
    setSelectedRows([]);
    rowSelection.selectedRows = [];
    setIsRemoving(false);
    setIsRemoveModalOpen(false);
  };

  const handleRemoveModalCancel = () => {
    setIsRemoveModalOpen(false);
  };

  return (
    <>
      <Segment color="blue">
        <Header size="medium">Email Aliases</Header>
        <Segment basic>
          <Label size="large" horizontal>
            <Icon name="mail" />
            Primary Email Alias:
          </Label>
          <span style={{ fontSize: "16px" }}>{primaryMailAlias}</span>
        </Segment>
        <Segment basic>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "24px",
            }}
          >
            <Button
              disabled={!canModifyGroup}
              color={canModifyGroup ? "green" : "grey"}
              id="mailAliasesAddButton"
              onClick={handleAdd}
              icon={"add"}
              content="Add Mail Alias"
            ></Button>
          </div>
          <ContentTable
            rowKey={getRowKey}
            pagination={{
              position: ["none", "none"],
            }}
            columns={tableColumns}
            dataSource={dataSource}
            rowSelection={rowSelection}
          />
        </Segment>
        <Segment basic>
          <Button
            id="mailAliasesRemoveSelectionButton"
            loading={isRemoving}
            disabled={!hasSelections || !canModifyGroup}
            color={!hasSelections || !canModifyGroup ? "grey" : "red"}
            onClick={handleRemove}
            icon="trash alternate"
          />
        </Segment>
      </Segment>
      <Modal
        closeIcon
        open={isAddModalOpen}
        onClose={handleAddModalCancel}
        size="mini"
        centered
      >
        <Modal.Header>Add Email Alias</Modal.Header>
        <Modal.Content>
          <Input
            key={emailInputKey}
            autoFocus
            icon="mail"
            iconPosition="left"
            label={cernEmailDomain}
            labelPosition="right"
            placeholder="Enter email alias to be added"
            value={inputValue}
            onChange={handleEmailInputChange}
            onBlur={handleEmailInputBlur}
            error={!!emailInputError}
            fluid
          />
          {emailInputError && (
            <span
              style={{
                color: "red",
                marginTop: "4px",
                marginLeft: "4px",
                display: "block",
              }}
            >
              {emailInputError}
            </span>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleAddModalCancel}>Cancel</Button>
          <Button
            positive
            onClick={handleAddModalOk}
            disabled={!!emailInputError}
          >
            Add
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        closeIcon
        open={isRemoveModalOpen}
        onClose={handleRemoveModalCancel}
        size="small"
        centered
      >
        <Modal.Header>
          <span>
            <Icon name="trash" color="red" style={{ marginRight: "1.5rem" }} />
            Confirm Deletion
          </span>
        </Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to remove the following email aliases?</p>
          <List
            bulleted
            items={[...selectedRows].map((item, index) => ({
              key: index,
              content: item,
            }))}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleRemoveModalCancel}>No</Button>
          <Button negative onClick={handleRemoveModalOk}>
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

MailAliases.propTypes = {
  primaryMailAlias: PropTypes.string,
  mailAliases: PropTypes.arrayOf(PropTypes.string),
  canModifyGroup: PropTypes.bool,
  updateGroupMailProperties: PropTypes.func,
};

export default MailAliases;
