import PropTypes from "prop-types";
import React, { useState } from "react";
import { Icon, Table, Popup } from "semantic-ui-react";
import styles from "./AuditRow.module.scss";
import moment from "moment-timezone";
import { withRouter } from "react-router-dom";
import {
  selectGroupById,
  getIdentityById,
  getAccountById,
  getApplicationById,
} from "../../actions/api";
import { connect } from "react-redux";
import { successAction, types } from "@authzsvc/api-lib";
import { Tooltip } from "antd";
import { TargetObjectTypes } from "../../models/targetObjectTypes";
import { sidebar_identities } from "../../config";

const AuditRow = ({
  item,
  selectGroupById,
  getIdentityById,
  getAccountById,
  getApplicationById,
  currentGroup,
}) => {
  const [popupContent, setPopupContent] = useState("Unavailable");

  const showIdentifier = async (type, id) => {
    if (item && type && id) {
      if (type === TargetObjectTypes.egroups) {
        let response = await selectGroupById(id);
        if (response.type === successAction(types.GET_GROUP_BY_ID)) {
          if (response.payload.data) {
            setPopupContent(response.payload.data.groupIdentifier);
          }
        }
      } else if (type === TargetObjectTypes.identities) {
        let response = await getIdentityById(id);
        if (response.type === successAction(types.GET_IDENTITY_BY_ID)) {
          if (response.payload.data && response.payload.data.upn) {
            setPopupContent(response.payload.data.upn);
          }
        }
      } else if (type === TargetObjectTypes.accounts) {
        let response = await getAccountById(id);
        if (response.type === successAction(types.GET_ACCOUNT_BY_ID)) {
          if (response.payload.data) {
            setPopupContent(response.payload.data.displayName);
          }
        }
      } else if (type === TargetObjectTypes.applications) {
        let response = await getApplicationById(id);
        if (response.type === successAction(types.GET_APPLICATION)) {
          if (response.payload.data) {
            setPopupContent(response.payload.data.displayName);
          }
        }
      }
    }
  };

  const getIdentifierRegex = (type) => {
    switch (type) {
      case TargetObjectTypes.egroups:
        return /GroupIdentifier='([^']+)'/i;
      case TargetObjectTypes.identities:
        return /Upn='([^']+)'/i;
      case TargetObjectTypes.accounts:
        return /DisplayName='([^']+)'/i;
      case TargetObjectTypes.applications:
        return /DisplayName='([^']+)'/i;
      default:
        return null;
    }
  };

  const getTargetUrl = (type, id) => {
    switch (type) {
      case TargetObjectTypes.egroups:
        return {
          url: `/group/${id}/details`,
          targetBlank: false,
        };
      case TargetObjectTypes.identities:
        return {
          url: `${sidebar_identities}/identities/${id}`,
          targetBlank: true,
        };
      default:
        return null;
    }
  };

  const parseIdentifier = (type, targetObject) => {
    if (!type || !targetObject) {
      return null;
    }

    const identifierRegex = getIdentifierRegex(type);
    if (!identifierRegex) {
      return null;
    }

    const identifierMatch = identifierRegex.exec(targetObject);
    const identifier = identifierMatch ? identifierMatch[1] : null;
    return {
      identifier,
      targetUrl: getTargetUrl(type, identifier),
    };
  };

  const firstTargetObject = parseIdentifier(
    item.targetObjectType1,
    item.targetObject1
  );
  const secondTargetObject = parseIdentifier(
    item.targetObjectType2,
    item.targetObject2
  );

  const prepareTargetElement = (type, targetObject) => {
    if (!type || !targetObject) {
      return null;
    }

    return (
      <>
        {targetObject.targetUrl && currentGroup !== targetObject.identifier ? (
          <a
            href={targetObject.targetUrl?.url}
            {...(targetObject.targetUrl?.targetBlank
              ? { target: "_blank", rel: "noopener noreferrer" }
              : {})}
          >
            {type} ({targetObject.identifier})
          </a>
        ) : (
          <span>
            {type} ({targetObject.identifier})
          </span>
        )}
      </>
    );
  };

  const prepareTargetCell = (type, targetObject, targetObjectId) => (
    <Table.Cell>
      {type &&
        (TargetObjectTypes[type] ? (
          targetObject ? (
            prepareTargetElement(type, targetObject)
          ) : (
            <Popup
              content={popupContent}
              trigger={
                <span onClick={() => showIdentifier(type, targetObjectId)}>
                  <a href={() => false}>
                    {type} ( {targetObjectId})
                  </a>
                </span>
              }
              on={["click"]}
            />
          )
        ) : (
          <span>
            {type} ( {targetObjectId})
          </span>
        ))}
    </Table.Cell>
  );

  return (
    <Table.Row key={item.id}>
      <Table.Cell className={styles.application}>
        {moment(item.time).tz("Europe/Zurich").format("DD/MM/YYYY, h:mm:ss a")}
      </Table.Cell>
      <Table.Cell>
        {item.displayName && <span>{item.displayName}</span>}
      </Table.Cell>
      <Table.Cell>{item.description}</Table.Cell>
      <Table.Cell>
        <Tooltip title={item.authorized}>
          {item.authorized === "Authorized" ? (
            <Icon name="check" color="green" />
          ) : (
            <Icon name="cancel" color="red" />
          )}
        </Tooltip>
        {item.operationType}
      </Table.Cell>
      <Table.Cell>
        {item.authorizationReason && <span>{item.authorizationReason}</span>}
      </Table.Cell>
      {prepareTargetCell(
        item.targetObjectType1,
        firstTargetObject,
        item.targetObjectId1
      )}
      {prepareTargetCell(
        item.targetObjectType2,
        secondTargetObject,
        item.targetObjectId2
      )}
    </Table.Row>
  );
};

AuditRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default withRouter(
  connect(null, {
    selectGroupById,
    getIdentityById,
    getAccountById,
    getApplicationById,
  })(AuditRow)
);
