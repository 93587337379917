import React from "react";
import { connect } from "react-redux";
import HeaderBarGroupsPortal from "./components/header/HeaderBarGroupsPortal";
import MainRouter from "./components/MainRouter";
import { FullPageLoader, CernFooter } from "@authzsvc/common-react-components";
import GroupActionsMenu from "./components/header/GroupActionsMenu";
import TitleProvider from "./components/title/TitleProvider";

const App = ({ ready }) => {
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {ready ? (
        <TitleProvider>
          <HeaderBarGroupsPortal />
          <GroupActionsMenu />
          <div style={{ flexGrow: 1, marginTop: "10px", marginBottom: "40px" }}>
            <MainRouter />
          </div>
        </TitleProvider>
      ) : (
        <FullPageLoader />
      )}
      <div style={{ flexGrow: 0 }}>
        <CernFooter
          requestLink="https://cern.service-now.com/service-portal?id=functional_element&name=GMS"
          incidentLink="https://cern.service-now.com/service-portal?id=functional_element&name=GMS"
        />
      </div>
    </div>
  );
};

export default connect((state) => ({
  ready: state.keycloak.ready,
  sidebarVisible: state.sidebar.visible,
}))(App);
