import { types, successAction } from "@authzsvc/api-lib";

const initialState = {
  direct: [],
  recursive: [],
  nestedTotal: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case successAction(types.GET_CURRENT_IDENTITY_GROUPS): {
      const groupMembership = action.payload.data;
      return { ...state, direct: [...groupMembership] };
    }
    case successAction(types.GET_CURRENT_IDENTITY_GROUPS_RECURSIVELY): {
      const page = action.payload.data;
      return { ...state, recursive: page, nestedTotal: page.length };
    }
    default:
      return state;
  }
};
