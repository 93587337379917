// Keycloak configs
export const keycloakUrl = process.env.REACT_APP_KEYCLOAK_URL;
export const keycloakRealm = process.env.REACT_APP_KEYCLOAK_REALM;
export const keycloakClientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;

export const refreshKeycloakToken =
  process.env.REACT_APP_KEYCLOAK_REFRESH_TOKEN === "true";

// Which role should be used to check if user is allowed to subscribe to groups
export const groupsUserRoleIdentifier = process.env.REACT_APP_GROUPS_USER_ROLE;

// Auth service client params
export const baseAuthorizationServerUrl =
  process.env.REACT_APP_AUTH_SERVICE_URL;
export const tokenExchangeEndpoint =
  process.env.REACT_APP_TOKEN_EXCHANGE_ENDPOINT;
export const authAudience = process.env.REACT_APP_AUDIENCE;
export const grantType = process.env.REACT_APP_GRANT_TYPE;
export const subjectTokenType = process.env.REACT_APP_SUBJECT_TOKEN_TYPE;

// Sidebar links
export const sidebar_resources = process.env.REACT_APP_SIDEBAR_RESOURCES;
export const sidebar_help = process.env.REACT_APP_SIDEBAR_HELP;
export const sidebar_applications = process.env.REACT_APP_SIDEBAR_APPLICATIONS;
export const sidebar_identities = process.env.REACT_APP_SIDEBAR_IDENTITIES;
export const sidebar_groups = process.env.REACT_APP_SIDEBAR_GROUPS;

// Admin role
export const adminRoleIdentifier = process.env.REACT_APP_ADMINISTRATOR_ROLE;

// GID assignment
export const enableGidAssignment =
  process.env.REACT_APP_ENABLE_GID_ASSIGNMENT === "true";

// Feature flag for enabling/disabling features
export const isFeatureEnabled =
  process.env.REACT_APP_IS_FEATURE_ENABLED === "true";

export const FormValidationStates = Object.freeze({
  Error: "error",
  Validating: "validating",
  Success: "success",
});

console.log("Setting environment as:");
console.log(process.env);
