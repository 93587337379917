import React, { useState } from "react";
import PropTypes from "prop-types";
import EGroupWarning from "../EGroupWarning";
import StandardGroupCriteria from "./StandardGroupCriteria.js";
import { notification } from "antd";
import { updateGroup, selectGroupById } from "../../../actions/api";
import { types as at, errorAction } from "@authzsvc/api-lib";
import { useDispatch } from "react-redux";

const GroupCriteria = ({ selectedGroup, canManageGroup }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  //Handles update criteria for both standard and role
  const handleUpdate = async (values) => {
    setLoading(true);

    const updatedGroup = { ...selectedGroup, ...values };
    const result = await dispatch(updateGroup(updatedGroup.id, updatedGroup));

    if (result.type === errorAction(at.UPDATE_GROUP)) {
      notification["error"]({
        message: result?.payload?.title || "Failed to update group",
        description:
          result?.payload?.data?.message || "Sorry, something went wrong",
      });
    }
    await dispatch(selectGroupById(updatedGroup.id));

    setLoading(false);
  };

  return (
    <>
      <EGroupWarning
        selectedGroup={selectedGroup}
        canManageGroup={canManageGroup}
      />
      <StandardGroupCriteria
        selectedGroup={selectedGroup}
        canManageGroup={canManageGroup}
        handleUpdate={handleUpdate}
        loading={loading}
      />
      {/* <RoleGroupCriteria /> */}
    </>
  );
};

GroupCriteria.propTypes = {
  selectedGroup: PropTypes.object,
  canManageGroup: PropTypes.bool,
};

export default GroupCriteria;
