import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Input, Icon, Menu, Button, Popup, Confirm } from "semantic-ui-react";
import {
  updateGroupMemberIdentityComment,
  updateGroupMemberGroupComment,
} from "../../actions/api";
import { errorAction, successAction, types } from "@authzsvc/api-lib";
import { notification } from "antd";

const GroupMembershipComment = ({ record, selectedGroupId }) => {
  const dispatch = useDispatch();

  const [comment, setComment] = useState(record.comment || "");
  const [isEditing, setIsEditing] = useState(false);
  const [originalComment, setOriginalComment] = useState(record.comment || "");
  const [menuOpen, setMenuOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleEdit = () => {
    setIsEditing(true);
    setMenuOpen(false);
  };

  const handleCancel = () => {
    setComment(originalComment);
    setIsEditing(false);
  };

  const handleSave = async () => {
    await postComment(comment);
    setOriginalComment(comment);
    setIsEditing(false);
  };

  const postComment = async (comment) => {
    let response;
    if (record.memberIdentity) {
      response = await dispatch(
        updateGroupMemberIdentityComment(
          selectedGroupId,
          record.memberIdentity.id,
          comment
        )
      );
    } else {
      response = await dispatch(
        updateGroupMemberGroupComment(
          selectedGroupId,
          record.memberGroup.id,
          comment
        )
      );
    }

    const isSuccessAction =
      response.type ===
        successAction(types.UPDATE_GROUP_MEMBER_IDENTITY_COMMENT) ||
      response.type === successAction(types.UPDATE_GROUP_MEMBER_GROUP_COMMENT);

    const isErrorAction =
      response.type ===
        errorAction(types.UPDATE_GROUP_MEMBER_IDENTITY_COMMENT) ||
      response.type === errorAction(types.UPDATE_GROUP_MEMBER_GROUP_COMMENT);

    if (isSuccessAction) {
      if (String(comment).trim() === "") {
        notification.success({
          message: "Comment deleted successfully",
        });
      } else if (String(comment).trim() !== "") {
        notification.success({
          message: "Comment saved successfully",
        });
      }
    } else if (isErrorAction) {
      notification.error({
        message: "An error occurred updating the comment",
      });
    }
  };

  const handleDelete = () => {
    setConfirmOpen(true);
    setMenuOpen(false);
  };

  const confirmDelete = async () => {
    setComment("");
    setOriginalComment("");
    setConfirmOpen(false);
    await postComment("");
  };

  const cancelDelete = () => {
    setConfirmOpen(false);
  };

  return (
    <div>
      <Input
        value={comment}
        disabled={!isEditing}
        style={{ color: "black", backgroundColor: "white", opacity: 1 }} // Ensures text color is black even when disabled
        onChange={(e) => setComment(e.target.value)}
        action={
          <Popup
            content={
              <Menu vertical style={{ boxShadow: "none", border: "none" }}>
                <Menu.Item onClick={handleEdit} disabled={isEditing}>
                  Edit
                </Menu.Item>
                <Menu.Item
                  onClick={handleDelete}
                  disabled={comment === "" || isEditing}
                  style={{ color: "red" }}
                >
                  Delete
                </Menu.Item>
              </Menu>
            }
            on="click"
            open={menuOpen}
            onOpen={() => !isEditing && setMenuOpen(true)}
            onClose={() => setMenuOpen(false)}
            trigger={
              <Button icon disabled={isEditing}>
                <Icon name="ellipsis vertical" />
              </Button>
            }
          />
        }
      />
      {isEditing && (
        <div style={{ marginTop: "10px" }}>
          <Button onClick={handleSave} primary>
            Save
          </Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </div>
      )}
      <Confirm
        open={confirmOpen}
        onCancel={cancelDelete}
        onConfirm={confirmDelete}
        content="Are you sure you want to delete this comment?"
        onClose={() => setConfirmOpen(false)} // Ensure menu is closed when confirmation is closed
      />
    </div>
  );
};

export default GroupMembershipComment;
