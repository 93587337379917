import React from "react";
import PropTypes from "prop-types";
import { Divider } from "antd";
import { LoadingWrapper } from "@authzsvc/common-react-components";
import PersonSearchButton from "./PersonSearchButton";
import OwnerLoader from "./OwnerLoader";

const OwnerEdit = ({ group, loading, onSelect, disabled }) => (
  <LoadingWrapper loading={loading}>
    {group ? (
      <>
        <OwnerLoader ownerId={group.ownerId} />{" "}
        {!disabled && (
          <>
            <Divider type="vertical" />{" "}
            <PersonSearchButton
              title="Assign new identity as group owner"
              onSelect={onSelect}
              icon="edit"
            />
          </>
        )}
      </>
    ) : (
      <span>None</span>
    )}
  </LoadingWrapper>
);

OwnerEdit.propTypes = {
  group: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default OwnerEdit;
