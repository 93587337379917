import React from "react";
import { connect } from "react-redux";
import GroupCreationForm from "./GroupCreationForm";
import { Container, Segment, Header, Icon } from "semantic-ui-react";
import { createStaticGroup } from "../../actions/api";
import { groupsUserRoleIdentifier } from "../../config";
import { showWarningNotification } from "../../util";

import PropTypes from "prop-types";

class GroupCreate extends React.Component {
  componentDidMount() {}

  state = {
    loading: false,
  };

  handleCreate = async (values) => {
    this.setState({ loading: true });
    const response = await this.props.createStaticGroup(values);
    if (response && response.payload && response.payload.data) {
      if (response.payload.request_id) {
        showWarningNotification(
          response.payload.message,
          "The prefix administrators have been informed"
        );
        this.props.history.push(`/requests`);
      } else {
        this.props.history.push(`/group/${response.payload.data.id}/details`);
      }
    } else {
      console.error(response);
      this.props.history.push(`/`);
    }
    this.setState({ loading: false });
  };

  render() {
    const { title, dynamic, icon } = this.props;

    return (
      <Container>
        {groupsUserRoleIdentifier === "groups_user" ? (
          <Segment color="blue">
            <Header size="medium">
              <Icon.Group>
                <Icon name={icon} />
                <Icon corner name="add" />
              </Icon.Group>{" "}
              {title}
            </Header>
            <GroupCreationForm
              isEdit={false}
              dynamic={dynamic}
              handleSubmit={this.handleCreate}
              loading={this.state.loading}
            />
          </Segment>
        ) : (
          <Segment placeholder>
            <Header icon>
              <Icon name="lock" />
              You don't have the access rights to create a group.
            </Header>
          </Segment>
        )}
      </Container>
    );
  }
}

GroupCreate.propTypes = {
  createStaticGroup: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  dynamic: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
};

export default connect(() => ({}), { createStaticGroup })(GroupCreate);
