import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Table, Header, Segment, Icon } from "semantic-ui-react";
import { successAction, types } from "@authzsvc/api-lib";
import { LoadingWrapper } from "@authzsvc/common-react-components";
import { resolveAuditsForEntity } from "../../actions/api";
import { isApiCallLoading } from "../../selectors";
import AuditRow from "./AuditRow";
import styles from "./EntityAuditLogs.module.scss";
import { showErrorNotification } from "../../util";

const DEFAULT_AUDIT_TABLE_QUERY_FIELDS = [
  "time",
  "description",
  "authorized",
  "operationType",
  "authorizationReason",
  "targetObjectId1",
  "targetObjectId2",
  "targetObjectType1",
  "targetObjectType2",
  "parentObjectId",
  "parentObjectType",
  "displayName",
  "targetObject1",
  "targetObject2",
];

// TODO: the number of rows retrieved should be configurable
const AUDIT_LOGS_LIMIT = 100;

const EntityAuditLogs = ({
  // props
  id,
  selectedGroup,
  // redux actions
  resolveAuditsForEntity,
}) => {
  const [auditLogs, setAuditLogs] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const entityId = selectedGroup?.id || id;
      const { payload, type } = await resolveAuditsForEntity(entityId, {
        limit: AUDIT_LOGS_LIMIT,
        field: DEFAULT_AUDIT_TABLE_QUERY_FIELDS,
      });
      if (payload && type === successAction(types.RESOLVE_AUDITS_FOR_ENTITY)) {
        setAuditLogs(payload.data);
      } else {
        showErrorNotification(payload.title, payload.data.message);
      }
      setIsLoading(false);
    };
    getData();
  }, [id, setAuditLogs, resolveAuditsForEntity, selectedGroup]);

  return (
    <Segment color="blue">
      <Header as="h2" className={styles.header}>
        {selectedGroup && (
          <>
            <Icon name="clipboard" /> Audit logs for:&nbsp;
            {selectedGroup.groupIdentifier}
          </>
        )}
      </Header>
      <LoadingWrapper loading={isLoading}>
        {auditLogs !== undefined && !isLoading && (
          <Table basic>
            <Table.Header>
              <Table.Row>
                {[
                  "Time",
                  "Caller",
                  "Description",
                  "Operation",
                  "Reason",
                  "Target 1",
                  "Target 2",
                ].map((header) => (
                  <Table.HeaderCell key={header}>{header}</Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {auditLogs.map((a) => (
                <AuditRow
                  key={a.id}
                  item={a}
                  currentGroup={selectedGroup?.groupIdentifier}
                />
              ))}
            </Table.Body>
          </Table>
        )}
      </LoadingWrapper>
    </Segment>
  );
};

EntityAuditLogs.propTypes = {};

export default withRouter(
  connect(
    (state, ownProps) => ({
      id: ownProps.match.params.id,
      loading: isApiCallLoading(state),
    }),
    {
      resolveAuditsForEntity,
    }
  )(EntityAuditLogs)
);
