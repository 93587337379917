import { Tag } from "antd";
import React from "react";
import { notification } from "antd";
import moment from "moment";

export const validateEmail = (email) => {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const renderUnconfirmed = (name, record) => {
  if (record.unconfirmed === true) {
    return (
      <Tag color="volcano" key={record.id}>
        <span>{record.unconfirmedEmail} (Unconfirmed Email)</span>
      </Tag>
    );
  } else {
    return <span>{name}</span>;
  }
};

export function showErrorNotification(title, message) {
  notification["error"]({
    message: title ?? "Something went wrong",
    description: message ?? "We are sorry! An error has occurred.",
  });
}

export function showWarningNotification(title, message) {
  notification["warning"]({
    message: title ?? "Something is missing",
    description: message ?? "Please check alerts",
  });
}

export function formatDateGroup(date) {
  const formatedDate = date ? moment(date).format("YYYY-MM-DD") : null;
  return formatedDate;
}
