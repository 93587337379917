import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";
import { Icon } from "semantic-ui-react";

/**
 * Tooltips for any kind of UI component
 */
const TooltipInfoIcon = ({ title, size = "small" }) => {
  return (
    <span>
      <Tooltip title={title}>
        <Icon color="blue" name="info circle" size={size} />
      </Tooltip>
    </span>
  );
};

TooltipInfoIcon.propTypes = {
  title: PropTypes.string.isRequired,
};

export default TooltipInfoIcon;
