import React, { useState, useEffect } from "react";
import {
  Button,
  Dropdown,
  Form,
  Header,
  Input,
  Modal,
  Segment,
  Loader,
  Icon,
} from "semantic-ui-react";
import { Table } from "antd";
import {
  standardOperators,
  getOperatorsForDataType,
  parseNewStandardCriteria,
  parseStandardGroupCriteria,
} from "./utils";
import { getDynamicCriteria } from "../../../actions/api";
import { useDispatch } from "react-redux";
import { notification } from "antd";
import { errorAction, types } from "@authzsvc/api-lib";

const StandardGroupCriteria = ({
  selectedGroup,
  canManageGroup,
  handleUpdate,
  loading,
}) => {
  const dispatch = useDispatch();
  const isReplicaGroup =
    selectedGroup.syncType === "SlaveWithPlaceholders" ||
    selectedGroup.syncType === "Slave";

  const canModifyGroup = canManageGroup && !isReplicaGroup;

  const [criteriaList, setCriteriaList] = useState([]);
  const [standardCriteria, setStandardCriteria] = useState(
    parseStandardGroupCriteria(selectedGroup.criteria)
  );
  const [newStandardCriteria, setNewStandardCriteria] = useState({
    field: "",
    operator: "",
    values: "",
  });
  const [standardModalOpen, setStandardModalOpen] = useState(false);
  const [standardSelectedRows, setStandardSelectedRows] = useState([]);
  const [operatorOptions, setOperatorOptions] = useState(standardOperators);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editCriteria, setEditCriteria] = useState(null);

  const hasStandardSelections = standardSelectedRows.length > 0;

  useEffect(() => {
    getCriteria();
    // eslint-disable-next-line
  }, []);

  const standardDataSource =
    standardCriteria?.map((criteria, i) => ({
      key: i,
      field: criteria.field,
      operator: criteria.operator,
      values: Array.isArray(criteria.values)
        ? criteria.values.join(", ")
        : criteria.values,
    })) || [];

  const standardRowSelection = {
    selectedRowKeys: standardSelectedRows,
    onChange: (selectedRowKeys) => {
      setStandardSelectedRows(selectedRowKeys);
    },
    getCheckboxProps: () => ({
      disabled: !canModifyGroup,
    }),
  };
  const standardTableColumns = [
    { title: "Field", dataIndex: "field", key: "field" },
    { title: "Operator", dataIndex: "operator", key: "operator" },
    { title: "Values", dataIndex: "values", key: "values" },
    {
      title: "Actions",
      key: "actions",
      render: (_, record, index) => (
        <Button
          icon="edit"
          color="blue"
          onClick={() => handleEditRow(index)}
          disabled={!hasStandardSelections && !canModifyGroup}
        />
      ),
    },
  ];

  const getCriteria = async () => {
    const criteriaList = await dispatch(getDynamicCriteria());

    const isErrorAction =
      criteriaList.type === errorAction(types.GET_DYNAMIC_GROUP_CRITERIA);

    if (isErrorAction) {
      notification.error({
        message: criteriaList.payload.title,
        description: criteriaList.payload.message,
      });
    } else {
      setCriteriaList(criteriaList.payload.data);
    }
  };

  const updateCriteria = (updatedCriteria = standardCriteria) => {
    const parsedCriteria = parseNewStandardCriteria(
      updatedCriteria,
      selectedGroup.criteria
    );
    handleUpdate({ criteria: parsedCriteria });
  };

  const formatCriteriaOptions = () => {
    const options = criteriaList
      .filter((criteria) => !criteria.restricted)
      .map((criteria) => ({
        key: criteria.id,
        text: criteria.description,
        value: criteria.id,
      }));

    return options;
  };

  const handleOperatorOptionsChange = (value) => {
    const dataType = criteriaList.find((c) => c.id === value)?.datatype;
    setOperatorOptions(getOperatorsForDataType(dataType));
  };

  const getRowKey = (criteria) => {
    return criteria?.key;
  };

  const handleAddStandardCondition = () => {
    if (
      newStandardCriteria.field &&
      newStandardCriteria.operator &&
      (newStandardCriteria.values ||
        newStandardCriteria.operator === "IsNull" ||
        newStandardCriteria.operator === "IsNotNull")
    ) {
      setStandardCriteria([...standardCriteria, newStandardCriteria]);
      setNewStandardCriteria({ field: "", operator: "", values: "" });
      setStandardModalOpen(false);
    }
  };

  const handleStandardConditionChange = (key, values) => {
    setNewStandardCriteria({ ...newStandardCriteria, [key]: values });
  };

  const handleRemoveStandardSelected = () => {
    if (!hasStandardSelections || !canModifyGroup) {
      return;
    }
    const updatedCriteria = standardCriteria.filter(
      (_, index) => !standardSelectedRows.includes(index)
    );
    updateCriteria(updatedCriteria);
    setStandardSelectedRows([]);
  };

  const handleEditRow = (rowIndex) => {
    setEditCriteria({ ...standardCriteria[rowIndex], index: rowIndex });
    setEditModalOpen(true);
  };

  const handleSaveEdit = () => {
    const updatedCriteria = [...standardCriteria];
    updatedCriteria[editCriteria.index] = {
      field: editCriteria.field,
      operator: editCriteria.operator,
      values: editCriteria.values,
    };
    setStandardCriteria(updatedCriteria);
    setEditModalOpen(false);
  };

  const handleEditChange = (key, value) => {
    setEditCriteria((prevCriteria) => {
      const updatedCriteria = { ...prevCriteria, [key]: value };

      // Reset or adjust values based on the operator
      if (key === "operator") {
        if (value === "IsNull" || value === "IsNotNull") {
          updatedCriteria.values = ""; // Clear values for null checks
        } else if (
          (value === "Between" || value === "NotBetween") &&
          !Array.isArray(updatedCriteria.values)
        ) {
          updatedCriteria.values = ["", ""]; // Initialize as an array for range operators
        } else if (
          value !== "Between" &&
          value !== "NotBetween" &&
          Array.isArray(updatedCriteria.values)
        ) {
          updatedCriteria.values = ""; // Reset to string for other operators
        }
      }

      return updatedCriteria;
    });
  };

  const renderValueInput = () => {
    switch (newStandardCriteria.operator) {
      case "Between":
      case "NotBetween":
        return (
          <>
            <Form.Field>
              <Input
                placeholder="Enter Max Value"
                fluid
                value={newStandardCriteria.values[0] || ""}
                onChange={(e) =>
                  handleStandardConditionChange("values", [
                    e.target.value,
                    newStandardCriteria.values[1],
                  ])
                }
              />
            </Form.Field>
            <Form.Field>
              <Input
                placeholder="Enter Min Value"
                fluid
                value={newStandardCriteria.values[1] || ""}
                onChange={(e) =>
                  handleStandardConditionChange("values", [
                    newStandardCriteria.values[0],
                    e.target.value,
                  ])
                }
              />
            </Form.Field>
          </>
        );
      case "IsNull":
      case "IsNotNull":
        return <Form.Field></Form.Field>;
      default:
        return (
          <Form.Field>
            <Input
              placeholder="Enter Value"
              fluid
              value={newStandardCriteria.values}
              onChange={(e) =>
                handleStandardConditionChange("values", e.target.value)
              }
            />
          </Form.Field>
        );
    }
  };

  // Render dynamic input fields in the Edit Modal
  const renderEditValueInput = () => {
    if (!editCriteria?.operator) return null;

    switch (editCriteria.operator) {
      case "Between":
      case "NotBetween":
        return (
          <>
            <Form.Field>
              <Input
                placeholder="Enter Min Value"
                fluid
                value={editCriteria.values?.[0] || ""}
                onChange={(e) =>
                  handleEditChange("values", [
                    e.target.value,
                    editCriteria.values?.[1],
                  ])
                }
              />
            </Form.Field>
            <Form.Field>
              <Input
                placeholder="Enter Max Value"
                fluid
                value={editCriteria.values?.[1] || ""}
                onChange={(e) =>
                  handleEditChange("values", [
                    editCriteria.values?.[0],
                    e.target.value,
                  ])
                }
              />
            </Form.Field>
          </>
        );

      case "IsNull":
      case "IsNotNull":
        return <Form.Field></Form.Field>;

      default:
        return (
          <Form.Field>
            <Input
              placeholder="Enter Value"
              fluid
              value={editCriteria.values || ""}
              onChange={(e) => handleEditChange("values", e.target.value)}
            />
          </Form.Field>
        );
    }
  };

  return (
    <>
      {loading ? (
        <Segment color="blue" size="huge" padded="very">
          <Loader active />
        </Segment>
      ) : (
        <Segment color="blue">
          <Header size="medium">Standard Group Criteria</Header>
          <Segment basic>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginBottom: "24px",
              }}
            >
              <Button
                color="green"
                disabled={!canModifyGroup}
                onClick={() => setStandardModalOpen(true)}
                icon="add"
                content="Add Criteria"
              />
            </div>
            <Table
              rowKey={getRowKey}
              style={{ overflow: "auto" }}
              pagination={{
                showSizeChanger: true,
                hideOnSinglePage: false,
              }}
              columns={standardTableColumns}
              dataSource={standardDataSource}
              rowSelection={standardRowSelection}
            />
          </Segment>
          <Segment basic>
            <Button
              disabled={!hasStandardSelections && !canModifyGroup}
              backgroundColor={!hasStandardSelections ? "grey" : "red"}
              color={!hasStandardSelections ? "grey" : "red"}
              onClick={handleRemoveStandardSelected}
              icon
            >
              <Icon size="small" name="trash alternate" />
            </Button>

            <Button
              onClick={() => updateCriteria()}
              color="green"
              size="large"
              disabled={!hasStandardSelections && !canModifyGroup}
            >
              Save
            </Button>
          </Segment>
        </Segment>
      )}

      <Modal
        closeIcon
        open={standardModalOpen}
        onClose={() => setStandardModalOpen(false)}
        centered
      >
        <Modal.Header>Add Standard Group Criteria</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Field>
                <Dropdown
                  placeholder="Select Field"
                  selection
                  options={formatCriteriaOptions()}
                  value={newStandardCriteria.field}
                  onChange={(e, { value }) => {
                    handleStandardConditionChange("field", value);
                    handleOperatorOptionsChange(value);
                  }}
                />
              </Form.Field>
              <Form.Field>
                <Dropdown
                  placeholder="Select Operator"
                  selection
                  options={operatorOptions}
                  value={newStandardCriteria.operator}
                  onChange={(e, { value }) => {
                    handleStandardConditionChange("operator", value);
                  }}
                  disabled={!newStandardCriteria.field}
                />
              </Form.Field>
              {renderValueInput()}
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setStandardModalOpen(false)}>Cancel</Button>
          <Button positive onClick={handleAddStandardCondition}>
            Add
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        closeIcon
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        centered
      >
        <Modal.Header>Edit Standard Group Criteria</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Field>
                <Dropdown
                  placeholder="Select Field"
                  selection
                  options={formatCriteriaOptions()}
                  value={editCriteria?.field}
                  onChange={(e, { value }) => handleEditChange("field", value)}
                />
              </Form.Field>
              <Form.Field>
                <Dropdown
                  placeholder="Select Operator"
                  selection
                  options={operatorOptions}
                  value={editCriteria?.operator}
                  onChange={(e, { value }) =>
                    handleEditChange("operator", value)
                  }
                  disabled={!editCriteria?.field}
                />
              </Form.Field>
              {renderEditValueInput()}
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setEditModalOpen(false)}>Cancel</Button>
          <Button positive onClick={handleSaveEdit}>
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default StandardGroupCriteria;
