import {
  types as at,
  errorAction,
  successAction,
  types,
} from "@authzsvc/api-lib";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getGroupsIOwn,
  selectGroupById,
  transferGroupOwner,
  updateGroup,
} from "../../actions/api";
import { adminRoleIdentifier } from "../../config";
import { isApiCallLoading, userTokenHasRole } from "../../selectors";

import { InfoCircleOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { withRouter } from "react-router";
import { Header, Placeholder, Segment } from "semantic-ui-react";
import { GroupSyncTypes } from "../../constants/enums";
import TooltipInfoIcon from "../common/TooltipInfoIcon";
import GroupEditForm from "./GroupEditForm";
import GroupScopesSelector from "./scopes/GroupScopesSelector";
import { showErrorNotification } from "../../util";

const PlaceholderLoading = ({ loading, children, small }) => {
  let placeholder = (
    <Placeholder fluid>
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder>
  );

  if (small) {
    placeholder = (
      <Placeholder>
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder>
    );
  }

  if (loading) {
    return placeholder;
  } else if (children) {
    return children;
  }
};

PlaceholderLoading.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  small: PropTypes.bool,
};

/**
 * Clones the group and it updates it with all the fields passed
 * @param {object} group
 * @param {object} updated
 */
const updateGroupObject = (group, updated) => {
  let newGroup = { ...group };
  Object.keys(updated).forEach((key) => {
    newGroup[key] = updated[key];
  });
  return newGroup;
};

class GroupDetails extends Component {
  state = {
    loading: false,
  };

  handleUpdate = async (values) => {
    this.setState({ loading: true });

    const updatedGroup = updateGroupObject(this.props.selectedGroup, values);
    const result = await this.props.updateGroup(updatedGroup.id, updatedGroup);

    if (result.type === errorAction(at.UPDATE_GROUP)) {
      showErrorNotification(result.payload.title, result.payload.data.message);
    }

    await this.props.selectGroupById(updatedGroup.id);
    this.setState({ loading: false });
  };

  handleSelectNewOwner = async (owner) => {
    this.setState({ loading: true });
    const { selectedGroup } = this.props;
    if (owner) {
      let ownerTransferResponse = await this.props.transferGroupOwner(
        selectedGroup.id,
        owner.id
      );
      if (
        ownerTransferResponse.type === successAction(at.TRANSFER_GROUP_OWNER)
      ) {
        if (ownerTransferResponse.payload.data.requestorId) {
          notification.warning({
            message: "Approval required by new administrator",
            description:
              "The owner or administrators of the group have been notified.",
          });
        } else {
          notification["success"]({
            message: "Ownership has been updated successfully!",
          });
          // Refresh the page
          await this.props.selectGroupById(selectedGroup.id);
          this.setState({ loading: false });
        }
      } else if (
        ownerTransferResponse.type === errorAction(at.TRANSFER_GROUP_OWNER)
      ) {
        notification.error({
          message:
            ownerTransferResponse?.payload?.title || "Sorry, an error occurred",
          description:
            ownerTransferResponse?.payload?.data?.message ||
            "Please try again later",
        });
        this.setState({ loading: false });
      }
    }
  };

  handleSelectNewAdminGroup = (group) => {
    if (group) {
      this.handleUpdate({ administratorsId: group.id });
    }
  };

  renderEgroupsSyncWarning = () => {
    const { selectedGroup, isAdmin, canManageGroup } = this.props;

    if (
      selectedGroup &&
      selectedGroup.syncType &&
      selectedGroup.syncType !== GroupSyncTypes.Master &&
      selectedGroup.syncType !== GroupSyncTypes.NoSync
    ) {
      const masterGroupMessage = (
        <>
          <p>
            <InfoCircleOutlined /> You cannot modify your subscription to this
            group because it is being synchronized from the{" "}
            <a
              href={`https://e-groups.cern.ch/e-groups/Egroup.do?egroupName=${selectedGroup.groupIdentifier}`}
            >
              legacy EGroups service
            </a>
            .
          </p>
          {(canManageGroup || isAdmin) && (
            <p>
              To make this group editable and migrate it to Grappa, please check
              the Groups Synchronization Options tab above.
            </p>
          )}
        </>
      );
      const syncErrorMessage = (
        <>
          <p>
            <InfoCircleOutlined /> You cannot edit this group because it is
            defined in the{" "}
            <a
              href={`https://e-groups.cern.ch/e-groups/Egroup.do?egroupName=${selectedGroup.groupIdentifier}`}
            >
              legacy EGroups service
            </a>{" "}
            with not yet fully supported dynamic criteria. You can still use
            this group to define permissions and roles for your applications.
          </p>
        </>
      );

      return (
        <Segment color="red">
          {selectedGroup.syncType !== "SyncError" && masterGroupMessage}
          {selectedGroup.syncType === "SyncError" && syncErrorMessage}
        </Segment>
      );
    }

    return null;
  };

  render() {
    const { loading, selectedGroup, isAdmin, canManageGroup } = this.props;

    return (
      <>
        <Segment color="blue">
          {this.renderEgroupsSyncWarning()}
          <Segment basic>
            <PlaceholderLoading loading={loading}>
              <GroupEditForm
                groupMemberships={this.props.groupMemberships}
                dynamic={selectedGroup.dynamic || false}
                onSelectOwner={this.handleSelectNewOwner}
                group={selectedGroup}
                handleSubmit={this.handleUpdate}
                loading={this.state.loading || loading}
                canManage={canManageGroup || isAdmin}
              />
            </PlaceholderLoading>
          </Segment>
        </Segment>
        {(canManageGroup || isAdmin) && (
          <>
            <Segment color="blue">
              <Header size="medium">
                <UnorderedListOutlined /> Group usage{" "}
                <TooltipInfoIcon
                  title={
                    "The group will be shared with the systems and services selected."
                  }
                />
              </Header>
              {selectedGroup.id && (
                <GroupScopesSelector groupId={selectedGroup.id} />
              )}
            </Segment>
          </>
        )}
      </>
    );
  }
}

GroupDetails.propTypes = {
  selectedGroup: PropTypes.object.isRequired,
  groupMemberships: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  canManageGroup: PropTypes.bool.isRequired,
  selectGroupById: PropTypes.func.isRequired,
  updateGroup: PropTypes.func.isRequired,
  transferGroupOwner: PropTypes.func.isRequired,
  getGroupsIOwn: PropTypes.func.isRequired,
};

export default withRouter(
  connect(
    (state) => ({
      selectedGroup: state.groups.selectedGroup,
      groupMemberships: state.groupMembership.direct,
      loading:
        isApiCallLoading(state, types.GET_GROUP_BY_ID) ||
        isApiCallLoading(state, types.GET_GROUPS_I_OWN),
      isAdmin: userTokenHasRole(state, adminRoleIdentifier),
    }),
    { selectGroupById, updateGroup, transferGroupOwner, getGroupsIOwn }
  )(GroupDetails)
);
