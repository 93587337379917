import React from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter } from "react-router";
import { LoginRequired } from "@authzsvc/common-react-components";
import { keycloakAuthenticated } from "../selectors";
import Dashboard from "./dashboard/Dashboard";
import { Segment } from "semantic-ui-react";
import MyGroupMemberships from "./group/MyGroupMemberships";
import GroupCreate from "./group/GroupCreate";
import GroupDetailsTabs from "./group/GroupDetailsTabs";
import GroupAdmin from "./group/GroupAdmin";
import RequestsWrapper from "./requests/RequestsWrapper";
import RequestDetailsWrapper from "./requests/RequestDetailsWrapper";
import { TitleContext } from "../hooks";

const MainRouter = ({ authenticated, history }) => {
  const { title, setCategory, setAction } = React.useContext(TitleContext);
  React.useEffect(() => {
    document.title = title.toString().trim();
  }, [title]);

  return (
    <>
      {authenticated ? (
        <Segment basic>
          <Switch>
            <Route
              path="/"
              exact
              render={(props) => {
                setCategory("Search");

                return <Dashboard {...props} />;
              }}
            />
            <Route
              path="/group/:id/details"
              exact
              render={(props) => {
                const { id } = props.match.params;
                setCategory("Details");

                return <GroupDetailsTabs key={id} {...props} />;
              }}
            />
            <Route
              path="/group/memberships/my"
              exact
              render={(props) => {
                setCategory("My Memberships");

                return <MyGroupMemberships {...props} />;
              }}
            />
            <Route
              path="/group/create"
              exact
              render={(props) => {
                setCategory("Create");
                setAction("Static group");

                return (
                  <GroupCreate
                    {...props}
                    dynamic={false}
                    title={"Create new static group"}
                    icon={"group"}
                  />
                );
              }}
            />
            <Route
              path="/group/createDynamic"
              exact
              render={(props) => {
                setCategory("Create");
                setAction("Dynamic group");

                return (
                  <GroupCreate
                    {...props}
                    dynamic={true}
                    title={"Create new dynamic group"}
                    icon={"group"}
                  />
                );
              }}
            />
            <Route
              path="/groups/admin"
              exact
              render={(props) => {
                setCategory("Admin");

                return <GroupAdmin {...props} />;
              }}
            />
            <Route
              path="/requests"
              exact
              render={(props) => {
                setCategory("Requests");

                return <RequestsWrapper {...props} />;
              }}
            />
            <Route
              path="/requests/:id"
              render={(props) => {
                const { id } = props.match.params;
                setCategory("Requests");
                setAction(`ID" ${id}`);

                return <RequestDetailsWrapper {...props} />;
              }}
            />
          </Switch>
        </Segment>
      ) : (
        <LoginRequired />
      )}
    </>
  );
};

export default withRouter(
  connect((state) => ({
    authenticated: keycloakAuthenticated(state),
  }))(MainRouter)
);
