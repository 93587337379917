import React from "react";
import PropTypes from "prop-types";
import { Segment, Header, Checkbox } from "semantic-ui-react";

const MailArchive = ({
  enableArchive,
  selectedGroup,
  canModifyGroup,
  updateGroupMailProperties,
}) => {
  const handleEnableArchiveSwitchChange = (_, data) => {
    updateGroupMailProperties({ enableArchive: data.checked });
  };

  return (
    <Segment color="blue">
      <Header size="medium">Mail Archive Properties</Header>
      <Segment basic>
        <Checkbox
          toggle
          label="Enable archive"
          disabled={!canModifyGroup}
          onChange={handleEnableArchiveSwitchChange}
          checked={enableArchive}
        />
        {enableArchive && (
          <Segment
            basic
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              marginLeft: "16px",
            }}
          >
            <a
              href={`https://egroups-archives.web.cern.ch/group/${selectedGroup.groupIdentifier}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Open archive
            </a>
          </Segment>
        )}
      </Segment>
    </Segment>
  );
};

MailArchive.propTypes = {
  enableArchive: PropTypes.bool,
  selectedGroup: PropTypes.object,
  canModifyGroup: PropTypes.bool,
  updateGroupMailProperties: PropTypes.func,
};

export default MailArchive;
