import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import AdminGroup from "../../common/AdminGroup";
import GroupSearchModal from "../../common/GroupSearchModal";
import { Divider } from "antd";

const AssignAdministratorGroup = React.forwardRef(
  ({ value = undefined, onChange, disabled }, ref) => {
    const [groupId, setGroupId] = useState(value || undefined);

    const selectNewGroup = (group) => {
      if (group && group.id) {
        setGroupId(group.id);
        triggerChange(group.id);
      }
    };

    const triggerChange = (changedValue) => {
      if (onChange) {
        onChange(changedValue);
      }
    };

    const clear = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setGroupId(undefined);
      triggerChange(undefined);
    };

    return (
      <span ref={ref}>
        {!groupId && <span>None </span>}
        {groupId && <AdminGroup adminId={groupId} />}
        {!disabled && (
          <>
            {" "}
            <Divider type="vertical" />
            <GroupSearchModal
              title="Assign admin group"
              onSelect={selectNewGroup}
              icon="edit"
            />
          </>
        )}

        {!disabled && groupId && (
          <>
            <Divider type="vertical" />{" "}
            <Button
              id="deleteButton"
              negative
              icon="trash alternate"
              onClick={clear.bind(this)}
              type="button"
            />{" "}
          </>
        )}
      </span>
    );
  }
);

AssignAdministratorGroup.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default AssignAdministratorGroup;
