import React, { Component } from "react";
import {
  getMyGroups,
  unsubscribeCurrentIdentityFromGroups,
  getCurrentIdentityGroups,
} from "../../actions/api";

import { connect } from "react-redux";
import { Segment, Header, Container, Icon } from "semantic-ui-react";
import { isApiCallLoading } from "../../selectors";
import { errorAction, types } from "@authzsvc/api-lib";
import {
  LoadingWrapper,
  ContentTable,
} from "@authzsvc/common-react-components";
import { defaultGroupTableColumns } from "../../constants";
import { Button, Divider } from "antd";
import { showErrorNotification } from "../../util";

class MyGroupMemberships extends Component {
  state = {
    selectedRowKeys: [],
    loadingUnsubscribe: false,
  };

  componentDidMount() {
    this.props.getMyGroups();
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onReset = () => {
    this.setState({
      selectedRowKeys: [],
    });
  };

  onUnsubscribe = async () => {
    this.setState({ loadingUnsubscribe: true });
    const {
      unsubscribeCurrentIdentityFromGroups,
      getCurrentIdentityGroups,
      getMyGroups,
    } = this.props;
    const { selectedRowKeys } = this.state;
    const response = await unsubscribeCurrentIdentityFromGroups(
      selectedRowKeys
    );
    if (
      response.type ===
      errorAction(types.UNSUBSCRIBE_CURRENT_IDENTITY_FROM_GROUPS)
    ) {
      showErrorNotification(
        response.payload.title,
        response.payload.data.message
      );
    }
    this.setState({ loadingUnsubscribe: false });
    this.onReset();
    getCurrentIdentityGroups();
    getMyGroups();
  };

  render() {
    const { loading, groups } = this.props;
    const { selectedRowKeys, loadingUnsubscribe } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    return (
      <Container>
        <Segment color="blue">
          <Header size="medium">
            <Icon name="users" circular />
            Group memberships
          </Header>

          <LoadingWrapper loading={loading}>
            <Segment basic>
              <div style={{ marginBottom: 16, display: "inline-block" }}>
                <Button
                  id="bulkUnsubscribeButton"
                  type="danger"
                  onClick={this.onUnsubscribe}
                  disabled={!hasSelected || loadingUnsubscribe}
                  loading={loadingUnsubscribe}
                >
                  Bulk unsubscribe
                </Button>
                <Divider type="vertical" />
                <Button
                  id="resetSelectionButton"
                  type="primary"
                  onClick={this.onReset}
                  disabled={!hasSelected || loadingUnsubscribe}
                  loading={loadingUnsubscribe}
                >
                  Reset selection
                </Button>
                <span style={{ marginLeft: 8 }}>
                  {hasSelected
                    ? `Selected ${selectedRowKeys.length} items`
                    : ""}
                </span>
              </div>
              <ContentTable
                rowKey="id"
                pagination={{
                  showSizeChanger: true,
                  hideOnSinglePage: true,
                }}
                rowSelection={rowSelection}
                columns={defaultGroupTableColumns}
                dataSource={groups}
              />
            </Segment>
          </LoadingWrapper>
        </Segment>
      </Container>
    );
  }
}

export default connect(
  (state) => ({
    groups: state.groups.myGroups,
    loading: isApiCallLoading(state, types.GET_MY_GROUPS),
  }),
  {
    getMyGroups,
    getCurrentIdentityGroups,
    unsubscribeCurrentIdentityFromGroups,
  }
)(MyGroupMemberships);
