import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getIdentityById } from "../../actions/api";
import Owner from "./Owner";
import { LoadingWrapper } from "@authzsvc/common-react-components";
import { types, errorAction } from "@authzsvc/api-lib";
import { showErrorNotification } from "../../util";

/**
 * Loads an owner given an ownerId and the getIdentityById function.
 */
const OwnerLoader = ({ ownerId, getIdentityById }) => {
  const [owner, setOwner] = useState(undefined);

  useEffect(() => {
    const getOwner = async () => {
      const ownerIdentity = await getIdentityById(ownerId, {});
      if (ownerIdentity && ownerIdentity.payload) {
        setOwner(ownerIdentity.payload.data);
      } else if (ownerIdentity.type === errorAction(types.GET_IDENTITY_BY_ID)) {
        showErrorNotification(
          ownerIdentity.payload.title,
          ownerIdentity.payload.data.message
        );
      }
    };
    if (ownerId) {
      getOwner();
    }
  }, [ownerId, getIdentityById, setOwner]);

  if (!ownerId) {
    return <>No owner</>;
  }

  return (
    <LoadingWrapper loading={!owner}>
      <>{owner && <Owner owner={owner} />}</>
    </LoadingWrapper>
  );
};

OwnerLoader.propTypes = {
  ownerId: PropTypes.string,
  getIdentityById: PropTypes.func.isRequired,
};

export default connect(null, { getIdentityById })(OwnerLoader);
