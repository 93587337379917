import * as at from "./types";

export const setBreadcrumbs = (nav) => (dispatch) =>
  dispatch({
    type: at.SET_BREADCRUMBS,
    payload: { nav: nav },
  });

export const clearGroupMembers = () => (dispatch) =>
  dispatch({
    type: at.CLEAR_GROUPS_DETAILS,
  });

export const clearGroupMemberIdentities = () => (dispatch) =>
  dispatch({
    type: at.CLEAR_GROUP_IDENTITIES,
  });

export const clearGroupMemberGroups = () => (dispatch) =>
  dispatch({
    type: at.CLEAR_GROUP_GROUPS,
  });

export const clearSearch = () => (dispatch) =>
  dispatch({
    type: at.CLEAR_SEARCH,
  });

export const clearSelectedGroup = () => (dispatch) =>
  dispatch({
    type: at.CLEAR_SELECTED_GROUP,
  });
