import React from "react";

/**
 * Hook used to manually update the page title
 * ( basic title [category] - action )
 * @type {React.Context<{setAction: setAction, setCategory: setCategory, title: string}>}
 */
export const TitleContext = React.createContext({
  title: "",
  setCategory: () => {},
  setAction: () => {},
});
