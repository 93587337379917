import { types, successAction } from "@authzsvc/api-lib";
import * as at from "../actions/types";

const initialState = {
  groupSearch: [],
  myGroups: [],
  groupsIOwn: [],
  selectedGroup: {},
  adminGroups: {},
  dynamicCriteria: [],
  groupRoleAssignments: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case successAction(types.SEARCH_GROUP):
      const groupSearch = action.payload.data;
      return { ...state, groupSearch: groupSearch };
    case successAction(types.GET_GROUP_BY_ID):
      const group = action.payload.data;
      return { ...state, selectedGroup: group };
    case successAction(types.GET_ADMIN_GROUP_BY_ID):
      const adminGroup = action.payload.data;
      const newAdminGroups = {
        ...state.adminGroups,
        [adminGroup.id]: adminGroup,
      };
      return { ...state, adminGroups: newAdminGroups };
    case successAction(types.GET_MY_GROUPS):
      const myGroups = [...action.payload.data];
      return { ...state, myGroups };
    case successAction(types.GET_GROUPS_I_OWN):
      const groupsIOwn = [...action.payload.data];
      return { ...state, groupsIOwn };
    case successAction(types.GET_DYNAMIC_GROUP_CRITERIA):
      const dynamicCriteria = [...action.payload.data];
      return { ...state, dynamicCriteria };
    case successAction(types.GET_GROUP_ROLE_ASSIGNMENTS):
      const groupRoleAssignments = [...action.payload.data];
      return { ...state, groupRoleAssignments };
    case at.CLEAR_SEARCH:
      return { ...state, groupSearch: [] };
    case at.CLEAR_SELECTED_GROUP:
      return { ...state, selectedGroup: {} };
    default:
      return state;
  }
};
