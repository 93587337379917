import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  getScopesForGroup,
  removeGroupFromScope,
  addGroupToScope,
} from "../../../actions/api";
import { Checkbox, Form, Segment } from "semantic-ui-react";
import { errorAction, types } from "@authzsvc/api-lib";
import { notification } from "antd";

const GroupScopesSelector = ({
  groupScopes,
  groupId,
  // api calls
  getScopesForGroup,
  removeGroupFromScope,
  addGroupToScope,
}) => {
  const [selectedGroupScopes, setSelectedGroupScopes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const response = await getScopesForGroup(groupId);
      if (response) {
        setSelectedGroupScopes(response.payload.data);
      }
      setLoading(false);
    };

    getData();
  }, [groupId, getScopesForGroup]);

  const updateScopes = async (updateFunction, scope) => {
    setLoading(true);
    const updateResponse = await updateFunction(groupId, scope.id);
    if (updateResponse && updateResponse.payload) {
      const getResponse = await getScopesForGroup(groupId);
      if (getResponse && getResponse.payload) {
        setSelectedGroupScopes(getResponse.payload.data);
      }
    } else if (
      updateResponse.type === errorAction(types.ADD_GROUP_TO_SCOPE) ||
      updateResponse.type === errorAction(types.REMOVE_GROUP_FROM_SCOPE)
    ) {
      notification["error"]({
        message: updateResponse.payload.title,
        description: updateResponse.payload.data.message,
      });
    }
    setLoading(false);
  };

  const handleScopeChange = (scope, isChecked) => {
    isChecked
      ? updateScopes(addGroupToScope, scope)
      : updateScopes(removeGroupFromScope, scope);
  };

  const getDisplayName = (scope) => {
    const displayNameMap = {
      EmailDistributionList: "Mailing List",
      ActiveDirectory:
        "Active Directory: for file systems and Windows access control",
    };
    return displayNameMap[scope.displayName] || scope.displayName;
  };

  return (
    <Segment basic loading={loading}>
      <Form>
        <Form.Group grouped>
          {groupScopes.map((scope) => (
            <Form.Field key={scope.id}>
              <Checkbox
                label={getDisplayName(scope)}
                checked={selectedGroupScopes.some((s) => s.id === scope.id)}
                onChange={(e, { checked }) => handleScopeChange(scope, checked)}
              />
            </Form.Field>
          ))}
        </Form.Group>
      </Form>
    </Segment>
  );
};

GroupScopesSelector.propTypes = {
  groupId: PropTypes.string.isRequired,
  groupScopes: PropTypes.array.isRequired,
  getScopesForGroup: PropTypes.func.isRequired,
  removeGroupFromScope: PropTypes.func.isRequired,
  addGroupToScope: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    groupScopes: state.groupScope,
  }),
  {
    getScopesForGroup,
    removeGroupFromScope,
    addGroupToScope,
  }
)(GroupScopesSelector);
