import _ from "lodash";

export const sidebarVisible = (state) => {
  return state.sidebar.visible;
};

export const getErrors = (state) => {
  return state.errors;
};

export const keycloakAuthenticated = (state) => {
  return state.keycloak.authenticated;
};

export const isApiCallLoading = (state, requestType) => {
  return state.loading[requestType];
};

export const selectFocusedRequest = (state, id) => {
  let request = state.requests.toApprove.find((r) => r.id === id);
  if (request) {
    return {
      request,
      toApprove: true,
    };
  } else {
    return {
      request: state.requests.my.find((r) => r.id === id),
      toApprove: false,
    };
  }
};

export const selectNonCompletedRequests = (state) =>
  _.filter(state.requests.toApprove, (p) => p.status === "Pending");

export const userTokenHasRole = (state, role) => {
  if (
    state.keycloak.instance &&
    state.keycloak.instance.tokenParsed &&
    state.keycloak.instance.tokenParsed.cern_roles
  ) {
    return state.keycloak.instance.tokenParsed.cern_roles.includes(role);
  }
  console.warn("No 'cern_roles' claim found in the Keycloak token!");
  return false;
};

export const userTokenHasGroupUserRole = (state, role) => {
  if (
    state.keycloak.instance &&
    state.keycloak.instance.tokenParsed &&
    state.keycloak.instance.tokenParsed.groups_user
  ) {
    return state.keycloak.instance.tokenParsed.groups_user.includes(role);
  }
  console.warn("No 'groups_user' claim found in the Keycloak token!");
  return false;
};
