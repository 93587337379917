import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import * as cfg from "./config";

import createRootReducer from "./reducers";
import {
  authServiceMiddlewareCreator,
  notificationMiddlewareCreator,
  successAction,
  types,
} from "@authzsvc/api-lib";

const messages = Object.freeze({
  [successAction(types.CREATE_STATIC_GROUP)]: "Group created successfully!",
  [successAction(types.UNSUBSCRIBE_FROM_GROUP)]:
    "Successfully unsubscribed from group",
  [successAction(types.UPDATE_GROUP)]: "Successfully updated group",
  [successAction(types.DELETE_GROUP_BY_ID)]: "Successfully deleted group",
  [successAction(types.ADD_GROUP_MEMBER_IDENTITY)]:
    "Successfully added group member identity",
  [successAction(types.ADD_GROUP_MEMBER_GROUP)]:
    "Successfully added group member group",
  [successAction(types.DELETE_GROUP_MEMBER_GROUP)]:
    "Successfully deleted group member group",
  [successAction(types.DELETE_GROUP_MEMBER_IDENTITY)]:
    "Successfully deleted group member identity",
  [successAction(types.ADD_GROUP_TO_SCOPE)]:
    "Successfully added scope to group",
  [successAction(types.REMOVE_GROUP_FROM_SCOPE)]:
    "Successfully removed scope from group",
  [successAction(types.ADD_GROUP_MEMBER_IDENTITIES)]:
    "Identities successfully added",
  [successAction(types.ADD_GROUP_MEMBER_GROUPS)]: "Groups successfully added",
});

const history = createBrowserHistory();

const middleware = [
  routerMiddleware(history),
  thunk,
  authServiceMiddlewareCreator(cfg),
  notificationMiddlewareCreator(messages),
];

let composed;
if (window.__REDUX_DEVTOOLS_EXTENSION__ !== undefined) {
  composed = compose(
    applyMiddleware(...middleware),
    window.__REDUX_DEVTOOLS_EXTENSION__()
  );
} else {
  composed = applyMiddleware(...middleware);
}

export { history };

export default createStore(createRootReducer(history), {}, composed);
