import { Button, Icon, Modal } from "semantic-ui-react";
import { Input } from "antd";
import GroupSubscriptionAction from "./GroupSubscriptionAction";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { successAction, types } from "@authzsvc/api-lib";
import {
  getGroupMemberIdentities,
  getCurrentIdentityGroupsRecursively,
} from "../../actions/api";
import { DEFAULT_GROUP_MEMBERS_TABLE_QUERY_FIELDS } from "../../constants";

class SubscriptionButton extends React.Component {
  state = {
    open: false,
    reasonValue: "",
    notAdmin: false,
  };

  async componentDidMount() {
    await this.toggleAdminRights();
  }

  toggleAdminRights = async () => {
    const identities = await this.props.getGroupMemberIdentities(
      this.props.group.id,
      {
        limit: 1,
        field: DEFAULT_GROUP_MEMBERS_TABLE_QUERY_FIELDS,
      }
    );

    if (identities.type !== successAction(types.GET_GROUP_MEMBER_IDENTITIES)) {
      this.setState({ notAdmin: true });
    }
  };

  toggleBelongsToGroup = () => {
    this.props.getCurrentIdentityGroupsRecursively();
  };

  renderActionButton = () => {
    const { group, disabled, requests, history } = this.props;

    const buttonProps = {
      color: "blue",
      content: "Subscribe",
      onClick: () => {
        this.setState({ open: true });
      },
    };

    if (requests) {
      const pendingSubscriptionRequest = requests.find(
        (item) =>
          item.targetId1 === group.id && item.status.toLowerCase() === "pending"
      );

      if (pendingSubscriptionRequest) {
        buttonProps.color = "orange";
        buttonProps.content = "Pending";
        buttonProps.onClick = () => {
          history.push(`/requests/${pendingSubscriptionRequest.id}`);
        };
      }
    }

    return (
      <Button
        id="subscribeButton"
        type="primary"
        disabled={disabled}
        {...buttonProps}
      />
    );
  };

  render() {
    const { group, disabled } = this.props;
    const belongsToGroup = this.props.groupMemberships.some(
      (m) => this.props.group.id === m.id
    );

    return group.approvalRequired && !belongsToGroup && this.state.notAdmin ? (
      <Modal
        size="tiny"
        closeOnEscape={false}
        open={this.state.open}
        closeOnDimmerClick={true}
        onClose={() => this.setState({ open: false })}
        trigger={this.renderActionButton()}
      >
        <Modal.Header>
          <Icon name="write" /> Subscribe to group: {group.group_id}
        </Modal.Header>
        <Modal.Content>
          <label>
            Approval is required to join this group. Please enter a reason for
            your request:
          </label>
          <Input
            id="subscriptionReasonInput"
            onChange={(e) => this.setState({ reasonValue: e.target.value })}
            value={this.state.reasonValue}
            placeholder="Subscription reason..."
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => this.setState({ open: false })}
            icon="cancel"
            labelPosition="left"
            color="red"
            content="Cancel"
          />
          <GroupSubscriptionAction
            belongsToGroup={belongsToGroup}
            toggleSubscription={this.toggleBelongsToGroup}
            closeModal={() => this.setState({ open: false })}
            disabled={disabled}
            groupId={group.id}
            selfSubscriptionType={group.selfSubscriptionType}
            reason={this.state.reasonValue}
            syncType={group.syncType}
          />
        </Modal.Actions>
      </Modal>
    ) : (
      <GroupSubscriptionAction
        belongsToGroup={belongsToGroup}
        toggleSubscription={this.toggleBelongsToGroup}
        disabled={disabled}
        groupId={group.id}
        selfSubscriptionType={group.selfSubscriptionType}
        reason={""}
        syncType={group.syncType}
      />
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      groupMemberships: state.groupMembership.direct,
      requests: state.requests.my,
    }),
    { getGroupMemberIdentities, getCurrentIdentityGroupsRecursively }
  )(SubscriptionButton)
);
