import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { deleteGroupById, getCurrentIdentityGroups } from "../../actions/api";
import { Popconfirm } from "antd";
import { Button, Icon } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { clearSelectedGroup } from "../../actions";
import { errorAction, types } from "@authzsvc/api-lib";
import { showErrorNotification } from "../../util";

class GroupDeletionAction extends Component {
  state = {
    loading: false,
  };

  handleDelete = async () => {
    const {
      deleteGroupById,
      clearSelectedGroup,
      getCurrentIdentityGroups,
      groupId,
      history,
    } = this.props;
    this.setState({ loading: true });
    const deletionResponse = await deleteGroupById(groupId);
    if (
      deletionResponse &&
      deletionResponse.type === errorAction(types.DELETE_GROUP_BY_ID)
    ) {
      showErrorNotification(
        deletionResponse.payload.title,
        deletionResponse.payload.data.message
      );

      this.setState({ loading: false });
    } else {
      clearSelectedGroup();
      this.setState({ loading: false });
      await getCurrentIdentityGroups();
      history.push("/groups/admin");
    }
  };

  render() {
    const { loading } = this.state;
    const { size = "small" } = this.props;
    return (
      <Popconfirm
        title="Are you sure delete this group?"
        onConfirm={this.handleDelete}
        okText="Yes"
        cancelText="No"
      >
        <Button id="deleteGroupButton" loading={loading} negative icon>
          <Icon size={size} name="trash alternate" />
        </Button>
      </Popconfirm>
    );
  }
}

GroupDeletionAction.propTypes = {
  groupId: PropTypes.string.isRequired,
  size: PropTypes.string,
};

export default withRouter(
  connect(null, {
    deleteGroupById,
    getCurrentIdentityGroups,
    clearSelectedGroup,
  })(GroupDeletionAction)
);
