import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { Segment, Dimmer, Loader } from "semantic-ui-react";
import { notification } from "antd"; // TODO: Currently there's no equivalent for this component in Semantic UI. Eventually a component can be created with a similar behaviour, or perhaps use an external notification library instead.
import {
  getGroupMailPropertiesForGroup,
  updateGroupMailPropertiesForGroup,
} from "../../../actions/api";
import MailAliases from "./MailAliases";
import MailPostingRestrictions from "./MailPostingRestrictions";
import MailDeliveryNotification from "./MailDeliveryNotification";
import MailArchive from "./MailArchive";
import EGroupWarning from "../EGroupWarning";

const GroupEmailProperties = ({ selectedGroup, canManageGroup }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [groupMailProperties, setGroupMailProperties] = useState({});
  const [isInitialized, setIsInitialized] = useState(false);

  const fetchGroupMailProperties = useCallback(async () => {
    try {
      setLoading(true);
      const response = await dispatch(
        getGroupMailPropertiesForGroup(selectedGroup.id)
      );
      setGroupMailProperties(response.payload.data);
    } catch (error) {
      notification.error({
        message: "Failed to fetch group mail properties.",
      });
    } finally {
      setIsInitialized(true);
      setLoading(false);
    }
  }, [dispatch, selectedGroup.id]);

  useEffect(() => {
    fetchGroupMailProperties();
  }, [fetchGroupMailProperties]);

  const isReplicaGroup =
    selectedGroup.syncType === "SlaveWithPlaceholders" ||
    selectedGroup.syncType === "Slave";
  const canModifyGroup = canManageGroup && !isReplicaGroup;
  const {
    id,
    primaryMailAlias,
    mailAliases = [],
    allowAuthenticatedMessagesOnly = false,
    identitiesAllowedToPost = [],
    groupsAllowedToPost = [],
    deliveryFailureReport,
    enableArchive = false,
  } = groupMailProperties || {};

  const updateGroupMailProperties = async (updates) => {
    try {
      const groupMailPropertiesWithUpdates = {
        ...groupMailProperties,
        ...updates,
      };
      setLoading(true);
      await dispatch(
        updateGroupMailPropertiesForGroup(
          selectedGroup.id,
          groupMailPropertiesWithUpdates
        )
      );
      fetchGroupMailProperties();
      notification.success({
        message: "Group Email Properties updated successfully!",
      });
    } catch (error) {
      notification.error({
        message: "Failed to update group mail properties.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <EGroupWarning
        selectedGroup={selectedGroup}
        canManageGroup={canManageGroup}
      />
      {isInitialized ? (
        <>
          <MailAliases
            primaryMailAlias={primaryMailAlias}
            mailAliases={mailAliases}
            canModifyGroup={canModifyGroup}
            updateGroupMailProperties={updateGroupMailProperties}
          />
          <MailPostingRestrictions
            groupMailPropertiesId={id}
            identitiesAllowedToPost={identitiesAllowedToPost}
            groupsAllowedToPost={groupsAllowedToPost}
            selectedGroup={selectedGroup}
            allowAuthenticatedMessagesOnly={allowAuthenticatedMessagesOnly}
            canModifyGroup={canModifyGroup}
            updateGroupMailProperties={updateGroupMailProperties}
            fetchGroupMailProperties={fetchGroupMailProperties}
            setLoading={setLoading}
          />
          <MailDeliveryNotification
            deliveryFailureReport={deliveryFailureReport}
            canModifyGroup={canModifyGroup}
            updateGroupMailProperties={updateGroupMailProperties}
          />
          <MailArchive
            enableArchive={enableArchive}
            selectedGroup={selectedGroup}
            canModifyGroup={canModifyGroup}
            updateGroupMailProperties={updateGroupMailProperties}
          />{" "}
        </>
      ) : (
        <Segment color="blue" size="huge" padded="very">
          <Loader active />
        </Segment>
      )}

      {loading && isInitialized && (
        <Dimmer
          active
          inverted
          page
          style={{ position: "fixed", zIndex: 1000 }}
        >
          <Loader inverted>Loading</Loader>
        </Dimmer>
      )}
    </div>
  );
};

GroupEmailProperties.propTypes = {
  selectedGroup: PropTypes.object,
  canManageGroup: PropTypes.bool,
};

export default GroupEmailProperties;
