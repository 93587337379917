import React, { useEffect } from "react";
import styles from "./GroupRoles.module.scss";
import { types } from "@authzsvc/api-lib";
import { Segment, Message, Header, Table, Icon } from "semantic-ui-react";
import { LoadingWrapper } from "@authzsvc/common-react-components";
import { getGroupRoleAssignments } from "../../actions/api";
import { connect } from "react-redux";
import { isApiCallLoading } from "../../selectors";
import PropTypes from "prop-types";
import { sidebar_applications } from "../../config";

const getAppPortalUrl = (appId) => `${sidebar_applications}/manage/${appId}`;

const getRoleInAppPortalUrl = (appId, roleId) =>
  `${sidebar_applications}/manage/${appId}/role/${roleId}`;

const GroupRoleCell = ({ roleAssignment }) => {
  return (
    <Table.Row key={roleAssignment.applicationId}>
      <Table.Cell className={styles.application}>
        <a
          href={getAppPortalUrl(roleAssignment.applicationId)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="linkify" /> {roleAssignment.applicationIdentifier}
        </a>
      </Table.Cell>
      <Table.Cell>
        <ul className={styles.noList}>
          {roleAssignment.roles.map((r) => (
            <li key={r.id}>
              <a
                href={getRoleInAppPortalUrl(r.applicationId, r.id)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="linkify" /> {r.name}
              </a>{" "}
              - {r.description}
            </li>
          ))}
        </ul>
      </Table.Cell>
    </Table.Row>
  );
};

export const GroupRoles = ({
  match,
  loading,
  // redux actions
  getGroupRoleAssignments,
  // redux props
  roleAssignments,
}) => {
  useEffect(() => {
    getGroupRoleAssignments(match.params.id);
  }, [getGroupRoleAssignments, match.params.id]);

  return (
    <Segment color="blue">
      <LoadingWrapper loading={loading}>
        {roleAssignments.length === 0 && (
          <Message
            warning
            header="No role assignments found!"
            content="This group is either not linked to any application via roles or you are not allowed to see the assignments."
          />
        )}
        {roleAssignments.length > 0 && (
          <>
            <Header as="h2">
              <Icon style={{ fontSize: "28px" }} name="handshake" />
              Group role assignments
            </Header>
            <Message
              content={
                <>
                  <p>
                    Membership to this group grants you the following roles in
                    the applications listed below.
                  </p>
                  <p>
                    Clicking on the name of the application or the role will
                    open a new tab with the application details. You may not
                    have permissions to read these details on the other portal.
                  </p>
                </>
              }
            />
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Application</Table.HeaderCell>
                  <Table.HeaderCell>Roles</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {roleAssignments.map((r) => (
                  <GroupRoleCell key={r.id} roleAssignment={r} />
                ))}
              </Table.Body>
            </Table>
          </>
        )}
      </LoadingWrapper>
    </Segment>
  );
};

GroupRoles.propTypes = {
  loading: PropTypes.bool.isRequired,
  roleAssignments: PropTypes.array.isRequired,
  getGroupRoleAssignments: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    roleAssignments: state.groups.groupRoleAssignments,
    loading: isApiCallLoading(state, types.GET_GROUP_ROLE_ASSIGNMENTS),
  }),
  {
    getGroupRoleAssignments,
  }
)(GroupRoles);
