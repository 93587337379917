import React from "react";
import PropTypes from "prop-types";
import { Icon, Segment } from "semantic-ui-react";

const EGroupWarning = ({ selectedGroup, canManageGroup }) => {
  const isReplicaGroup =
    selectedGroup.syncType === "SlaveWithPlaceholders" ||
    selectedGroup.syncType === "Slave";

  return (
    <>
      {canManageGroup && isReplicaGroup && (
        <Segment color="red">
          <p>
            <Icon name="exclamation circle" />
            You cannot modify this group because it is being synchronized from
            the legacy EGroups service
          </p>
          <a
            href={`https://e-groups.cern.ch/e-groups/Egroup.do?egroupName=${selectedGroup.groupIdentifier}`}
          >
            Access here to modify this group
          </a>
        </Segment>
      )}
    </>
  );
};

EGroupWarning.propTypes = {
  selectedGroup: PropTypes.object,
  canManageGroup: PropTypes.bool,
};

export default EGroupWarning;
