import { types, successAction } from "@authzsvc/api-lib";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case successAction(types.GET_GROUP_SCOPES):
      const groupScopes = action.payload.data;
      return [...groupScopes];
    default:
      return state;
  }
};
