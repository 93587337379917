import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  GroupSyncTypes,
  GroupSyncTypeNames,
  GroupSyncDescriptions,
} from "../../../constants/enums";
import {
  Radio,
  Form,
  Button,
  Segment,
  Placeholder,
  Header,
} from "semantic-ui-react";
import { Popconfirm } from "antd";

const GroupSyncTypeSelector = ({ groupType, loading, onChange }) => {
  const [selectedGroupType, setSelectedGroupType] = useState(
    groupType || undefined
  );
  const [dirty, setDirty] = useState(false);

  const resetGroupType = () => {
    setSelectedGroupType(groupType);
    setDirty(false);
  };

  const submitGroupChange = async () => {
    await onChange(selectedGroupType);
    resetGroupType();
  };

  const handleChange = (e, { value }) => {
    setSelectedGroupType(value);
    setDirty(true);
  };

  const createRadioBox = (name) => (
    <Form.Field key={GroupSyncTypes[name]}>
      <Radio
        label={GroupSyncTypeNames[name]}
        name="radioGroup"
        value={name}
        checked={selectedGroupType === name}
        onChange={handleChange}
      />{" "}
      <span>- {GroupSyncDescriptions[name]}</span>
    </Form.Field>
  );

  if (loading) {
    return (
      <Placeholder>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder>
    );
  }

  return (
    <>
      <Segment basic>
        <Form>{Object.keys(GroupSyncTypes).map((k) => createRadioBox(k))}</Form>
      </Segment>
      <Segment basic>
        <Button
          id="resetSelectionButton"
          onClick={resetGroupType}
          disabled={dirty === false}
          secondary
        >
          Reset selection
        </Button>
        <Popconfirm
          title={
            <Header as="h4" color={"red"}>
              Are you sure you wish to perform this operation?
            </Header>
          }
          // visible={modalVisible}
          onConfirm={submitGroupChange}
          onCancel={resetGroupType}
        >
          <Button id="changeButton" disabled={dirty === false} primary>
            Change
          </Button>
        </Popconfirm>
      </Segment>
    </>
  );
};

GroupSyncTypeSelector.propTypes = {
  groupType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default GroupSyncTypeSelector;
