import { types, successAction } from "@authzsvc/api-lib";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case successAction(types.GET_IDENTITY):
      return action.payload.data;
    default:
      return state;
  }
};
