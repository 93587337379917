import React, { useEffect, useState } from "react";

import { Button, Input, Modal, Checkbox } from "semantic-ui-react";
import { notification } from "antd";
import { useDispatch } from "react-redux";
import { putGroupMembersCSV, getGroupMembersCSV } from "../../../actions/api";
import { types as at, errorAction } from "@authzsvc/api-lib";
import TooltipInfoIcon from "../../common/TooltipInfoIcon";
import { showErrorNotification } from "../../../util";

const CSVModal = ({ isModalOpen, onClose, group, getMembers, isDownload }) => {
  const [visible, setVisible] = useState(false);
  const [file, setFile] = useState(null);
  const [isOverwrite, setIsOverwrite] = useState(false);
  const [isCompatibilityMode, setIsCompatibilityMode] = useState(false);
  const dispatch = useDispatch();

  const handleCancel = () => {
    setVisible(false);
    setFile(null);
    setIsOverwrite(false);
    setIsCompatibilityMode(false);
    onClose();
  };

  const handleFileChange = (event) => {
    const newFile = event.target.files[0];
    if (newFile) {
      setFile(newFile);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const newFile = event.dataTransfer.files[0];
    if (newFile) {
      setFile(newFile);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleMerge = async () => {
    if (!file) return;

    const csvBlob = new Blob([file], { type: "text/csv" });
    const formData = new FormData();
    formData.append("file", csvBlob, file.name);

    const result = await dispatch(
      putGroupMembersCSV(
        group.groupIdentifier,
        isCompatibilityMode,
        isOverwrite,
        formData
      )
    );

    if (result.payload.status === 204) {
      notification["info"]({
        message: "No changes were made.",
        description: "No new members to merge.",
      });
      onClose();
    } else if (result.type === errorAction(at.PUT_GROUP_MEMBER_CSV)) {
      showErrorNotification(result.payload.title, result.payload.data.message);
    } else {
      await getMembers();
      notification["success"]({
        message: "Members have been merged successfully!",
      });
      setFile(null);
      onClose();
    }
  };

  const handleDownloadCSV = async () => {
    const result = await dispatch(
      getGroupMembersCSV(group.groupIdentifier, {
        compatibilityMode: isCompatibilityMode,
      })
    );

    if (result.type === errorAction(at.GET_GROUP_MEMBERS_CSV)) {
      showErrorNotification(result.payload.title, result.payload.data.message);
    } else {
      const blob = new Blob([result.payload], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `group-${group.groupIdentifier}-members.csv`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      notification["success"]({
        message: "CSV has been downloaded successfully!",
      });
      onClose();
    }
  };

  useEffect(() => {
    setVisible(isModalOpen);
  }, [isModalOpen]);

  return (
    <Modal
      style={{ width: "400px", margin: "auto" }}
      size="small"
      closeIcon
      open={visible}
      onClose={handleCancel}
    >
      <Modal.Header style={{ textAlign: "left" }}>
        {isDownload ? "Download a list of members" : "Add a list of members"}
      </Modal.Header>

      <Modal.Content style={{ textAlign: "start" }}>
        <p style={{ marginBottom: "1rem" }}>
          {isDownload
            ? "Click 'Download' to retrieve the current list of members in CSV format."
            : "Drag and drop your CSV file here or click to select a file. Ensure your CSV has the "}
          {!isDownload && (
            <>
              <a
                href="https://auth.docs.cern.ch/groups/csv/"
                target="_blank"
                rel="noopener noreferrer"
              >
                required format
              </a>
            </>
          )}
        </p>
        <div>
          <Checkbox
            id="checkbox_compatibility_mode"
            toggle
            onChange={() => setIsCompatibilityMode(!isCompatibilityMode)}
            checked={isCompatibilityMode}
            label="E-groups compatibility"
            style={{ marginBottom: "1rem", marginRight: "4px" }}
          />
          <TooltipInfoIcon
            title={
              isDownload
                ? "Select this to download the current list of members compatible with E-groups. If you download a file compatible with E-groups, remember to toggle the 'E-groups compatibility' option when importing it back."
                : "Select this to add new members from the CSV to the current group without affecting existing members."
            }
          />
        </div>
        {!isDownload && (
          <div>
            <Checkbox
              id="checkbox_overwrite"
              toggle
              onChange={() => setIsOverwrite(!isOverwrite)}
              checked={isOverwrite}
              label="Overwrite existing members"
              style={{ marginBottom: "1rem", marginRight: "4px" }}
            />
            <TooltipInfoIcon
              title={
                "Selecting this option will completely replace current members with those in the CSV. Consider downloading the current list for backup if unsure."
              }
            />
          </div>
        )}

        {!isDownload && (
          <div
            id="csv-members-input-modal"
            className="custom-file-upload"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            style={{
              marginBottom: "1rem",
              justifyContent: "center",
              padding: "1rem",
              border: "2px dashed #ccc",
              borderRadius: "4px",
            }}
          >
            <Input
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="file-input"
            />
            <label
              htmlFor="file-input"
              style={{
                cursor: "pointer",
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "4px",
                display: "inline-block",
                backgroundColor: "#f9f9f9",
                width: "100%",
                textAlign: "center",
              }}
            >
              <i className="file icon" />{" "}
              {file ? file.name : "Drag & Drop or Choose File"}
            </label>
          </div>
        )}

        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              id="cancelButton"
              onClick={handleCancel}
              content="Cancel"
              color={"red"}
              style={{ marginRight: "0.5rem" }}
            />
            {isDownload ? (
              <Button
                id="download_csv"
                onClick={handleDownloadCSV}
                icon={"download"}
                content="Download"
                color={"green"}
              />
            ) : (
              <Button
                id="importButton"
                onClick={handleMerge}
                content="Import"
                disabled={!file}
                color={"green"}
              />
            )}
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};
export default CSVModal;
