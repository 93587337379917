import React from "react";
import { connect } from "react-redux";
import { isApiCallLoading, selectNonCompletedRequests } from "../../selectors";
import { RequestsHeader } from "@authzsvc/common-react-components";

const RequestsHeaderButton = ({
  // state
  loading,
  requests,
}) => {
  return (
    <RequestsHeader link={"/requests"} loading={loading} requests={requests} />
  );
};

export default connect((state) => ({
  loading: isApiCallLoading(state, "getRequestsToApprove"),
  requests: selectNonCompletedRequests(state),
}))(RequestsHeaderButton);
