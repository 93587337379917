import React, { Component } from "react";
import {
  getCurrentIdentityGroups,
  getCurrentIdentityGroupsRecursively,
  getIdentity,
  getMyGroups,
  getMyRequests,
  getRequestsToApprove,
  getGroupScopes,
} from "../../actions/api";
import { isApiCallLoading, selectNonCompletedRequests } from "../../selectors";
import { types } from "@authzsvc/api-lib";

import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import { Menu, Icon } from "semantic-ui-react";

class GroupMembershipsButton extends Component {
  async componentDidMount() {
    this.props.getIdentity();
    this.props.getCurrentIdentityGroups();
    this.props.getGroupScopes();
    this.props.getMyGroups();
    this.props.getMyRequests();
    this.props.getRequestsToApprove();
    this.props.getCurrentIdentityGroupsRecursively();
  }

  render() {
    const { groupMemberships, requests, totalNestedMemberships } = this.props;

    return (
      <Menu.Menu attached="top" style={{ overflow: "visible !important" }}>
        <Menu.Item
          as={NavLink}
          to="/group/memberships/my"
          activeClassName="active"
          exact
          content={
            <>
              <Icon name="group" size="small" /> <span>Group Memberships:</span>{" "}
              {groupMemberships.length}
              {totalNestedMemberships > 0 && (
                <> / {totalNestedMemberships} (nested)</>
              )}
            </>
          }
        />
        <Menu.Item
          as={NavLink}
          to="/requests"
          activeClassName="active"
          exact
          content={
            <>
              <Icon name="bell" />( {requests.length} ) Requests
            </>
          }
        />
      </Menu.Menu>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      groupMemberships: state.groupMembership.direct,
      totalNestedMemberships: state.groupMembership.nestedTotal,
      loading: isApiCallLoading(state, types.GET_CURRENT_IDENTITY_GROUPS),
      requests: selectNonCompletedRequests(state),
    }),
    {
      getCurrentIdentityGroups,
      getCurrentIdentityGroupsRecursively,
      getIdentity,
      getMyGroups,
      getRequestsToApprove,
      getMyRequests,
      getGroupScopes,
    }
  )(GroupMembershipsButton)
);
