import { combineReducers } from "redux";
import { keycloakReducer as keycloak } from "@authzsvc/keycloak-js-react";
import { connectRouter } from "connected-react-router";
import groups from "./groups";
import loading from "./loading";
import groupMembership from "./groupMembership";
import identity from "./identity";
import groupMemberIdentities from "./groupMemberIdentities";
import groupMemberGroups from "./groupMemberGroups";
import groupScope from "./groupScope";
import requests from "./requests";
import sidebar from "./sidebar";

export default (history) =>
  combineReducers({
    keycloak,
    groupScope,
    groupMemberGroups,
    groupMemberIdentities,
    identity,
    groupMembership,
    requests,
    groups,
    loading,
    sidebar,
    router: connectRouter(history),
  });
