import React, { useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";
import { Container, Segment, Header, Icon } from "semantic-ui-react";
import { notification } from "antd";
import { types, errorAction } from "@authzsvc/api-lib";
import {
  LoadingWrapper,
  ContentTable,
  SearchBar,
} from "@authzsvc/common-react-components";
import {
  defaultGroupTableColumns,
  DEFAULT_GROUP_TABLE_QUERY_FIELDS,
} from "../../constants";
import { isApiCallLoading } from "../../selectors";
import { getGroupsIOwn } from "../../actions/api";
import styles from "./GroupAdmin.module.scss";

const GroupAdmin = ({
  getGroupsIOwn,
  ownGroups,
  loading,
  itemsPerPage = 10,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const columnsWithSorting = defaultGroupTableColumns.map((column) => ({
    ...column,
    sorter: (a, b) => {
      const aValue = a[column.dataIndex];
      const bValue = b[column.dataIndex];

      if (typeof aValue === "string" && typeof bValue === "string") {
        return aValue.localeCompare(bValue);
      }

      if (aValue && bValue && aValue.displayName && bValue.displayName) {
        return aValue.displayName.localeCompare(bValue.displayName);
      }

      if (aValue < bValue) return -1;
      if (aValue > bValue) return 1;
      return 0;
    },
  }));

  const handleSearch = useCallback(
    async (text, filter, limit) => {
      const queryFilter = {
        field: DEFAULT_GROUP_TABLE_QUERY_FIELDS,
      };

      if (text && filter) {
        queryFilter.filter = `groupIdentifier:${filter}:${text}`;
      } else if (text) {
        queryFilter.filter = `groupIdentifier:${text}`;
      }

      if (limit) {
        queryFilter.limit = limit;
      }

      const response = await getGroupsIOwn(queryFilter);
      if (response.type === errorAction(types.GET_GROUPS_I_OWN)) {
        notification.error({
          message: response.payload.title,
          description: response.payload.data.message,
        });
      }
    },
    [getGroupsIOwn]
  );

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  return (
    <Container>
      <Segment color="blue">
        <Header size="medium">
          <Icon.Group>
            <Icon name="group" />
            <Icon corner name="cog" />
          </Icon.Group>{" "}
          Groups I own or manage
        </Header>
        <SearchBar
          onSearch={handleSearch}
          searchValue={searchValue}
          onSearchValueChange={setSearchValue}
          filter={true}
        />
        <LoadingWrapper loading={loading}>
          <Segment basic>
            <ContentTable
              rowKey="id"
              pagination={{
                showSizeChanger: true,
                hideOnSinglePage: true,
                showTotal: (total) =>
                  `Total of ${total} ${total === 1 ? "group" : "groups"}`,
                defaultPageSize: itemsPerPage,
              }}
              columns={columnsWithSorting}
              dataSource={ownGroups}
              className={styles.rightAlignedSort}
            />
            {ownGroups.length <= itemsPerPage && (
              <div className={styles.totalGroups}>
                Total of {ownGroups.length}{" "}
                {ownGroups.length === 1 ? "group" : "groups"}
              </div>
            )}
          </Segment>
        </LoadingWrapper>
      </Segment>
    </Container>
  );
};

export default connect(
  (state) => ({
    ownGroups: state.groups.groupsIOwn,
    loading: isApiCallLoading(state, types.GET_GROUPS_I_OWN),
  }),
  { getGroupsIOwn }
)(GroupAdmin);
