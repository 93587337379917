import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { searchForGroup } from "../../actions/api";

import { Button, Message } from "semantic-ui-react";
import _ from "lodash";
import { Modal, AutoComplete } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ContentTable } from "@authzsvc/common-react-components";
import { errorAction, successAction, types as at } from "@authzsvc/api-lib";
import { showErrorNotification } from "../../util";

const GroupSearchModal = ({
  onSelect,
  searchForGroup,
  title,
  icon,
  isReplicaGroup,
  buttonText,
}) => {
  const [visible, setVisible] = useState(false);
  const [searchIsLoading, setSearchIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  const modalColumns = [
    {
      title: "ID",
      dataIndex: "groupIdentifier",
    },
    {
      title: "Name",
      dataIndex: "displayName",
    },
    {
      title: "Action",
      dataIndex: "id",
      render: (_, record) => (
        <Button id="recordButton" positive onClick={() => handleSelect(record)}>
          <PlusOutlined />
        </Button>
      ),
    },
  ];

  const handleSelect = (record) => {
    setVisible(false);
    onSelect(record);
  };

  const openUpdateModal = (e) => {
    e.preventDefault();
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const debouncedSearch = _.debounce((value) => {
    if (value.length >= 2) {
      setSearchIsLoading(true);
      searchForGroup({
        filter: `groupIdentifier:StartsWith:${value}`,
        sort: "groupIdentifier",
        limit: 30,
      }).then((action) => {
        if (action.type === successAction(at.SEARCH_GROUP)) {
          setSearchIsLoading(false);
          setDataSource(action.payload.data);
        } else if (action.type === errorAction(at.SEARCH_GROUP)) {
          showErrorNotification(
            action.payload.title,
            action.payload.data.message
          );

          setDataSource(action.payload.data);
        }
      });
    }
  }, 500);

  return (
    <>
      <Button
        onClick={openUpdateModal}
        positive
        icon={icon}
        content={buttonText}
        type="button"
      />
      <Modal
        style={{ minWidth: "90%" }}
        centered
        title={title}
        visible={visible}
        onOk={handleCancel}
        onCancel={handleCancel}
        destroyOnClose
        transitionName=""
        maskTransitionName=""
      >
        {isReplicaGroup && (
          <Message
            warning
            content={
              <p>
                Selected group is of type 'Replica'. Please note that adding
                group members is not allowed. Any users added will be
                automatically removed during synchronization.
              </p>
            }
          />
        )}
        <p>Type at least 2 characters of the group's identifier name.</p>
        <AutoComplete
          onChange={debouncedSearch}
          onSearch={debouncedSearch}
          enterButton
          placeholder="Group identifier"
        />
        <ContentTable
          style={{ marginTop: "10px" }}
          loading={searchIsLoading}
          rowKey="id"
          pagination={{
            pageSize: 10,
          }}
          columns={modalColumns}
          dataSource={dataSource}
        />
      </Modal>
    </>
  );
};

GroupSearchModal.propTypes = {
  title: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  searchForGroup: PropTypes.func.isRequired,
};

export default connect(null, { searchForGroup })(GroupSearchModal);
