import React, { useState } from "react";
import { Checkbox, Modal } from "semantic-ui-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { errorAction, successAction, types as at } from "@authzsvc/api-lib";
import { patchComputingGroup } from "../../actions/api";
import { showErrorNotification } from "../../util";

function ComputingGroupAction({
  groupComputingGroup,
  groupId,
  patchComputingGroup,
}) {
  const [checked, setChecked] = useState(groupComputingGroup);
  const [disabled, setDisabled] = useState(groupComputingGroup);
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const changeCheckedStatus = async () => {
    const fields = [
      { path: "isComputingGroup", op: "replace", value: "true" },
      { path: "gid", op: "replace", value: null },
    ];
    const result = await patchComputingGroup(groupId, fields);
    if (result.type === successAction(at.PATCH_GROUP_BY_ID)) {
      setChecked(true);
      setDisabled(true);
    } else if (result.type === errorAction(at.PATCH_GROUP_BY_ID)) {
      showErrorNotification(result.payload.title, result.payload.data.message);
    }
    setIsOpen(false);
  };

  return (
    <div>
      <Modal
        trigger={
          <Checkbox
            onChange={toggleModal}
            checked={checked}
            disabled={disabled}
          />
        }
        open={isOpen}
        header="Warning!"
        content="Once you proceed with this action, your choice cannot be reversed!"
        style={{ width: "500px" }}
        actions={[
          {
            key: "cancel",
            content: "Cancel",
            color: "red",
            positive: false,
            onClick: toggleModal,
          },
          {
            key: "done",
            content: "Accept",
            positive: true,
            onClick: changeCheckedStatus,
          },
        ]}
      />
    </div>
  );
}

ComputingGroupAction.propTypes = {
  groupId: PropTypes.string,
  groupComputingGroup: PropTypes.bool,
};

export default connect(null, { patchComputingGroup })(ComputingGroupAction);
