import { Tooltip, Modal } from "antd";
import { Button, Icon } from "semantic-ui-react";
import React, { useState } from "react";
import { GroupMembershipRestrictions } from "@authzsvc/common-react-components";
import { Header } from "semantic-ui-react";
import PropTypes from "prop-types";

const GroupMembershipModal = ({ groupId, disabled, onCancel }) => {
  const [visible, setVisible] = useState(false);

  const handleCancel = () => {
    onCancel();
    setVisible(false);
  };

  return (
    <>
      {!disabled && !visible && (
        <Tooltip title="Edit membership restrictions">
          <Button
            id="membershiptRestrictionsButton"
            onClick={() => setVisible(true)}
            icon
            color="green"
            type="button"
          >
            <Icon name="edit" />
          </Button>
        </Tooltip>
      )}

      <Modal
        style={{ minWidth: "90%" }}
        centered
        visible={visible}
        onOk={handleCancel}
        onCancel={handleCancel}
        transitionName=""
        maskTransitionName=""
      >
        <Header size="small">
          <Icon name="plug" />
          <Header.Content>
            Membership restrictions :
            <Header.Subheader>
              If specified, it will not be possible to add group members that
              are not in the restriction groups. No restrictions are applied if
              the list is empty.
            </Header.Subheader>
          </Header.Content>
        </Header>
        {visible && <GroupMembershipRestrictions groupId={groupId} />}
      </Modal>
    </>
  );
};

GroupMembershipModal.propTypes = {
  groupId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};
export default GroupMembershipModal;
