import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { searchForPerson } from "../../actions/api";
import { PersonSearchModal } from "@authzsvc/common-react-components";

const PersonSearchButton = ({ title, searchForPerson, onSelect, icon }) => {
  return (
    <PersonSearchModal
      title={title}
      color="green"
      onSelect={onSelect}
      searchForPerson={searchForPerson}
      icon={icon}
    />
  );
};

PersonSearchButton.propTypes = {
  onSelect: PropTypes.func.isRequired,
  searchForPerson: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default connect(() => ({}), { searchForPerson })(PersonSearchButton);
