import { Tooltip, notification } from "antd";
import { connect } from "react-redux";
import { Segment, Container, Header } from "semantic-ui-react";
import React from "react";
import { clearSearch } from "../../actions";
import { searchForGroup } from "../../actions/api";
import { types, errorAction } from "@authzsvc/api-lib";
import { isApiCallLoading } from "../../selectors";
import {
  LoadingWrapper,
  ContentTable,
  SearchBar,
} from "@authzsvc/common-react-components";
import {
  defaultGroupTableColumns,
  DEFAULT_GROUP_TABLE_QUERY_FIELDS,
} from "../../constants";
import SubscriptionButton from "../group/SubscriptionButton";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    // Initialize state
    this.state = {
      searchValue: "",
    };
  }

  // Method to handle input change
  handleInputChange = (value) => {
    this.setState({ searchValue: value });
  };

  groupColumns = [
    ...defaultGroupTableColumns,
    {
      title: "Action",
      render: (_, group) => {
        const disabled = this.disableSubscription(group);
        return (
          <Tooltip title={disabled.message} trigger={"hover"}>
            <span>
              <SubscriptionButton group={group} disabled={disabled.disabled} />
            </span>
          </Tooltip>
        );
      },
    },
  ];

  componentDidMount() {
    this.props.clearSearch();
  }

  disableSubscription(group) {
    let disabledSubscription = {
      disabled: false,
      message: "Modify group subscription",
    };
    if (group.selfSubscriptionType === "Closed") {
      disabledSubscription.disabled = true;
      disabledSubscription.message =
        "This group does not allow self subscription.";
    }
    if (group.syncType !== "NoSync" && group.syncType !== "Master") {
      disabledSubscription.disabled = true;
      disabledSubscription.message =
        "This group is managed via E-groups, please visit e-groups.cern.ch to subscribe";
    }
    if (
      group.owner &&
      group.owner.id === this.props.identity &&
      disabledSubscription.disabled
    ) {
      disabledSubscription.disabled = false;
      disabledSubscription.message = "Modify group subscription";
    }
    if (
      this.props.groupMemberships &&
      this.props.groupMemberships.find((g) => g.id === group.id) &&
      disabledSubscription.disabled
    ) {
      disabledSubscription.disabled = false;
      disabledSubscription.message = "Modify group subscription";
    }
    return disabledSubscription;
  }

  handleSearch = async (text, filter, limit) => {
    const groupTableQueryFields = [
      ...DEFAULT_GROUP_TABLE_QUERY_FIELDS,
      "selfSubscriptionType",
      "approvalRequired",
    ];
    const response = await this.props.searchForGroup({
      filter: `groupIdentifier:${filter}:${text}`,
      sort: "groupIdentifier",
      limit,
      field: groupTableQueryFields,
    });

    if (response.type === errorAction(types.SEARCH_GROUP)) {
      notification.error({
        message: response.payload.title,
        description: response.payload.data.message,
      });
    }
  };

  render() {
    const { groupSearch, loadingSearch } = this.props;
    const groupSearchMapped = groupSearch.map((gr) => ({ ...gr, key: gr.id }));
    return (
      <Container>
        <Segment size="small" color="blue">
          <Header as="h1" textAlign="center">
            <Header.Content>Group Management System</Header.Content>
          </Header>
          <SearchBar
            onSearch={this.handleSearch}
            searchValue={this.state.searchValue}
            onSearchValueChange={this.handleInputChange}
            filter={true}
          />
        </Segment>
        <LoadingWrapper loading={loadingSearch}>
          <Segment basic>
            <ContentTable
              pagination={{
                showSizeChanger: true,
                hideOnSinglePage: true,
              }}
              columns={this.groupColumns}
              dataSource={
                groupSearchMapped.length > 0 ? groupSearchMapped : null
              }
            />
          </Segment>
        </LoadingWrapper>
      </Container>
    );
  }
}

export default connect(
  (state) => ({
    groupSearch: state.groups.groupSearch,
    loadingSearch: isApiCallLoading(state, types.SEARCH_GROUP),
    groupMemberships: state.groupMembership.direct,
    identity: state.identity,
  }),
  { searchForGroup, clearSearch }
)(Dashboard);
