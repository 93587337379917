import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Tab,
  Container,
  Header,
  Icon,
  Grid,
  Label,
  Loader,
  Segment,
} from "semantic-ui-react";
import { Tooltip } from "antd";
import GroupDetails from "./GroupDetails";
import { types } from "@authzsvc/api-lib";
import { getGroupsIOwn, selectGroupById } from "../../actions/api";
import { isApiCallLoading, userTokenHasRole } from "../../selectors";
import GroupEmailProperties from "./GroupEmailProperties/GroupEmailProperties";
import GroupCriteria from "./GroupCriteria/GroupCriteria";
import GroupMembers from "./GroupMembers";
import EntityAuditLogs from "./EntityAuditLogs";
import GroupTreeView from "./GroupTreeView";
import GroupSyncTypePanel from "./syncTypes/GroupSyncTypePanel";
import GroupRoles from "./GroupRoles";
import styles from "./Group.module.css";
import { TitleContext } from "../../hooks";
import SubscriptionButton from "./SubscriptionButton";
import { adminRoleIdentifier, isFeatureEnabled } from "../../config";

const GroupDetailsTabs = (props) => {
  const {
    match,
    // redux data
    loading,
    selectedGroup,
    isAdmin,
    identity,
    groupMemberships,
    groupsIOwn,
    // redux actions
    selectGroupById,
    getGroupsIOwn,
  } = props;

  const { setAction } = React.useContext(TitleContext);

  const [activeIndex, setActiveIndex] = useState("0");

  useEffect(() => {
    selectGroupById(match.params.id);
    getGroupsIOwn();
  }, [selectGroupById, match.params.id, getGroupsIOwn]);

  useEffect(() => {
    if (selectedGroup) {
      setAction(`ID: ${selectedGroup.groupIdentifier}`);
    }
  }, [selectedGroup, setAction]);

  const groupMembershipInfo = useMemo(() => {
    return {
      owner: selectedGroup.ownerId === identity.id,
      admin: [
        ...groupsIOwn.map((g) => g.id),
        ...groupMemberships.map((g) => g.id),
      ].includes(selectedGroup.administratorsId),
      member: groupMemberships.some((i) => i.id === selectedGroup.id),
    };
  }, [
    groupMemberships,
    identity.id,
    selectedGroup.id,
    selectedGroup.ownerId,
    selectedGroup.administratorsId,
    groupsIOwn,
  ]);

  const getPanes = () => {
    let panes = [
      {
        menuItem: "Details",
        render: function EditGroupTab() {
          return (
            <GroupDetails
              canManageGroup={
                groupMembershipInfo.owner || groupMembershipInfo.admin
              }
              {...props}
            />
          );
        },
      },
      {
        menuItem: "Members",
        render: function MembersTab() {
          return (
            <GroupMembers
              canManageGroup={
                groupMembershipInfo.owner || groupMembershipInfo.admin
              }
              {...props}
            />
          );
        },
      },
      {
        menuItem: "Group role assignments",
        render: function GroupRolesTab() {
          return <GroupRoles {...props} />;
        },
      },
      {
        menuItem: "Group tree view",
        render: function GroupTreeViewTab() {
          return <GroupTreeView {...props} />;
        },
      },
      {
        menuItem: "Group synchronization options",
        render: function GroupSyncTypeTab() {
          return <GroupSyncTypePanel {...props} />;
        },
      },
      {
        menuItem: "Audit logs",
        render: function AuditLogs() {
          return <EntityAuditLogs {...props} selectedGroup={selectedGroup} />;
        },
      },
      selectedGroup.dynamic && {
        menuItem: "Criteria",
        render: function CriteriaTab() {
          return (
            <GroupCriteria
              selectedGroup={selectedGroup}
              canManageGroup={
                groupMembershipInfo.owner || groupMembershipInfo.admin
              }
            />
          );
        },
      },
      isFeatureEnabled && {
        menuItem: "Email Properties",
        render: function EmailPropertiesTab() {
          return (
            <GroupEmailProperties
              selectedGroup={selectedGroup}
              canManageGroup={
                groupMembershipInfo.owner || groupMembershipInfo.admin
              }
            />
          );
        },
      },
    ].filter(Boolean);

    if (selectedGroup && selectedGroup.syncType === "SyncError") {
      panes.splice(panes.length - 1, 1);
    } else if (
      !isAdmin &&
      !groupMembershipInfo.owner &&
      !groupMembershipInfo.admin
    ) {
      panes.splice(2);
    }

    return panes;
  };

  return (
    <Container>
      {loading || selectedGroup?.id === undefined ? (
        <Segment basic>
          <Loader active />
        </Segment>
      ) : (
        <>
          <Header size="medium">
            <Icon style={{ fontSize: "32px" }} name="users" />
            <Header.Content style={{ width: "100%" }}>
              <Grid>
                <Grid.Column width={8}>
                  <div>Group: {selectedGroup.groupIdentifier}</div>
                  {groupMembershipInfo.owner && (
                    <Tooltip
                      title="You are the owner of this group"
                      trigger="hover"
                    >
                      <Label color="red" size="mini" tag>
                        Owner
                      </Label>
                    </Tooltip>
                  )}
                  {groupMembershipInfo.admin && (
                    <Tooltip
                      title="You are an admin of this group"
                      trigger="hover"
                    >
                      <Label color="blue" size="mini" tag>
                        Admin
                      </Label>
                    </Tooltip>
                  )}
                  {groupMembershipInfo.member && (
                    <Tooltip
                      title="You are a member of this group"
                      trigger="hover"
                    >
                      <Label color="green" size="mini" tag>
                        Member
                      </Label>
                    </Tooltip>
                  )}
                </Grid.Column>
                <Grid.Column width={8} textAlign="right">
                  {selectedGroup.selfSubscriptionType !== "Closed" && (
                    <SubscriptionButton group={selectedGroup} />
                  )}
                </Grid.Column>
              </Grid>
            </Header.Content>
          </Header>
          <Tab
            renderActiveOnly={true}
            menu={{
              secondary: true,
              pointing: true,
              className: styles.wrapped,
            }}
            panes={getPanes()}
            activeIndex={activeIndex}
            onTabChange={(e, data) =>
              setActiveIndex(data.activeIndex.toString())
            }
          />
        </>
      )}
    </Container>
  );
};

GroupDetailsTabs.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  selectedGroup: PropTypes.object,
  isAdmin: PropTypes.bool,
  identity: PropTypes.object,
  groupMemberships: PropTypes.array,
  groupsIOwn: PropTypes.array,
  selectGroupById: PropTypes.func.isRequired,
  getGroupsIOwn: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    loading: isApiCallLoading(state, types.GET_GROUP_BY_ID),
    selectedGroup: state.groups.selectedGroup,
    isAdmin: userTokenHasRole(state, adminRoleIdentifier),
    identity: state.identity,
    groupMemberships: state.groupMembership.recursive,
    groupsIOwn: state.groups.groupsIOwn,
  }),
  {
    selectGroupById,
    getGroupsIOwn,
  }
)(GroupDetailsTabs);
