import KeycloakWrapper from "@authzsvc/keycloak-js-react";
import Keycloak from "keycloak-js";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import * as cfg from "./config";
import * as serviceWorker from "./serviceWorker";
import store, { history } from "./store";

import "antd/dist/antd.css";
import { ConnectedRouter } from "connected-react-router";
import "semantic-ui-cern/dist/semantic.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <KeycloakWrapper
        keycloak={
          new Keycloak({
            url: cfg.keycloakUrl,
            realm: cfg.keycloakRealm,
            clientId: cfg.keycloakClientId,
          })
        }
        refresh={true}
        keycloakParams={{
          onLoad: "login-required",
          promiseType: "native",
          flow: "standard",
        }}
      >
        <App />
      </KeycloakWrapper>
    </ConnectedRouter>
  </Provider>
);

serviceWorker.unregister();
