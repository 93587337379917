import { FormOutlined, UserOutlined } from "@ant-design/icons";
import { Checkbox, DatePicker, Form, Input } from "antd";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import { searchGroupIdentifier } from "../../actions/api";
import styles from "./Group.module.css";

import moment from "moment";
import { FormValidationStates } from "../../config";
import {
  PrivacyRadioGroup,
  privacyTypes,
  resourceCategories,
  ResourceCategoryRadioGroup,
  SelfSubscriptionRadioGroup,
  selfSubscriptionTypes,
} from "../../constants";
import { DynamicGroupTypes, GroupSyncTypes } from "../../constants/enums";
import TooltipInfoIcon from "../common/TooltipInfoIcon";
import AssignAdministratorGroup from "./formHelpers/AssignAdministratorGroup";
import { showErrorNotification, formatDateGroup } from "../../util";

const helpText = (error) => {
  return error ? <label className={styles.errorText}>{error}</label> : <></>;
};

const openNotificationsForAllValidationErrors = (formErrors) => {
  Object.keys(formErrors).forEach((key) => {
    const contents = (
      <>
        {formErrors[key].errors.map((errorMessage) => (
          <p key={key}>{errorMessage.message}</p>
        ))}
      </>
    );
    const message = `Validation error for the field: "${key}"`;
    showErrorNotification(message, contents);
  });
};

const formItemLayout = {
  style: { marginBottom: "0px" },
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

const GroupCreationForm = ({
  groupScopes,
  dynamic,
  handleSubmit,
  loading,
  form,
  searchGroupIdentifier,
}) => {
  const [displayNameStatus, setDisplayNameStatus] = useState("");
  const [displayNameError, setDisplayNameError] = useState("");
  const [groupIdentifierStatus, setGroupIdentifierStatus] = useState("");
  const [groupIdentifierError, setGroupIdentifierError] = useState("");
  const { getFieldError, isFieldTouched } = form;

  const identity = useSelector((state) => state.identity);

  const groupIdError =
    (isFieldTouched("groupIdentifier") && getFieldError("groupIdentifier")) ||
    groupIdentifierError;
  const groupDisplayNameError =
    (isFieldTouched("displayName") && getFieldError("displayName")) ||
    displayNameError;
  const descriptionError =
    isFieldTouched("description") && getFieldError("description");
  const errors = groupIdError || groupDisplayNameError || descriptionError;

  const handleSubmitWrapper = async (e) => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (!err) {
        let processedValues = { ...values, dynamic };

        const scopesEnabledByDefault = groupScopes
          .filter(
            (scope) =>
              scope.displayName === "ActiveDirectory" ||
              scope.displayName === "EmailDistributionList"
          )
          .map((scope) => scope.id);

        processedValues = {
          ...processedValues,
          syncType: processedValues.syncEgroups
            ? GroupSyncTypes.Master
            : GroupSyncTypes.NoSync,
          assignedScopes: scopesEnabledByDefault,
        };

        if (dynamic) {
          processedValues = {
            ...processedValues,
            dynamicGroupType: processedValues.includeExmp
              ? DynamicGroupTypes.FullDynamic
              : DynamicGroupTypes.Dynamic,
            criteria: {
              left: "personID",
              operator: "Eq",
              right: identity.personId,
            },
          };
        }

        // Remove hours, minutes, seconds and milliseconds from the date
        processedValues = {
          ...processedValues,
          validityLimit: formatDateGroup(processedValues.validityLimit),
        };

        delete processedValues.syncEgroups;
        await handleSubmit(processedValues);
      } else {
        openNotificationsForAllValidationErrors(err);
      }
    });
  };

  /**
   * Creates a decorator for a field type, utility function
   * @param  {[type]} fieldName    the name of the field
   * @param  {[type]} config       config specific to the field
   * @param  {[type]} initialValue the initial value to populate the field
   * @return {[type]}              A field
   */
  const createFieldDecorator = (fieldName, config, initialValue) => {
    const { getFieldDecorator } = form;
    if (initialValue !== undefined) {
      config.initialValue = initialValue;
    }
    return getFieldDecorator(fieldName, config);
  };

  const createButtons = (errors) => {
    return (
      <>
        <Form.Item {...formItemLayout}>
          <Button
            id="createGroupButton"
            size="large"
            type="submit"
            disabled={
              !_.isNil(errors) ||
              !(
                displayNameStatus === FormValidationStates.Success &&
                groupIdentifierStatus === FormValidationStates.Success &&
                !_.isNil(form.getFieldValue("description"))
              )
            }
            loading={loading}
          >
            Create
          </Button>
        </Form.Item>
      </>
    );
  };

  const getDynamicGroupsContent = () => {
    if (!dynamic) {
      return null;
    }

    return (
      <>
        <Form.Item
          {...formItemLayout}
          label={
            <span>
              Include ex-users and ex-employees?{" "}
              <TooltipInfoIcon
                title="Include all people who were registered in the CERN people database at any time to the results of the dynamic group criteria. When disabled (default option) only people with an active CERN registration will appear in the group."
                size={"small"}
              />
            </span>
          }
        >
          {createFieldDecorator(
            "includeExmp",
            {
              valuePropName: "checked",
            },
            false
          )(<Checkbox />)}
        </Form.Item>
      </>
    );
  };

  const validateGroupDisplayName = async (e) => {
    const inputValue = e?.target?.value || "";
    const groupDisplayName = inputValue.trim();

    if (groupDisplayName && !/^\s/.test(e.target.value)) {
      setDisplayNameStatus(FormValidationStates.Validating);
      const resp = await searchGroupIdentifier("DisplayName", [
        groupDisplayName,
      ]);
      if (resp.payload && resp.payload.data) {
        const groupDataUpdated = resp.payload.data.find(
          (o) => o.value === groupDisplayName
        );
        const taken = groupDataUpdated.isTaken;
        let nameStatus = FormValidationStates.Success;
        let nameError = null;
        if (taken && groupDataUpdated.value === groupDisplayName) {
          nameStatus = FormValidationStates.Error;
          nameError = "The Group display name is already taken!";
        }
        setDisplayNameStatus(nameStatus);
        setDisplayNameError(nameError);
      }
    } else {
      setDisplayNameStatus(FormValidationStates.Error);
      setDisplayNameError(
        "The Group display name cannot be empty or start with a space."
      );
    }
  };

  const getGroupIdentifierErrorMessage = (groupIdentifier) => {
    let groupIdentifierError = null;
    if (!groupIdentifier.match(/^[a-z_\-0-9]*$/)) {
      groupIdentifierError =
        "The Group identifier contains invalid characters. Only letters, numbers, " +
        "dashes and underscores are allowed.";
      // } else if (!groupIdentifier.match(/^[a-z0-9].*[a-z0-9]$/)) {
    } else if (
      groupIdentifier.length > 0 &&
      (!groupIdentifier.match(/^[a-z0-9]/) ||
        !groupIdentifier.match(/[a-z0-9]$/))
    ) {
      groupIdentifierError =
        "The Group identifier must start and end with a letter or number.";
    } else if (groupIdentifier.length < 3) {
      groupIdentifierError =
        "The Group identifier is too short (minimum 3 characters).";
    } else if (groupIdentifier.length > 128) {
      groupIdentifierError =
        "The Group identifier is too long (maximum 128 characters).";
    } else if (!groupIdentifier.includes("-")) {
      groupIdentifierError =
        "The Group identifier must contain at least one dash ('-') character.";
    }
    return groupIdentifierError;
  };

  const validateGroupIdentifier = async (e, validateUniqueness) => {
    const groupIdentifier = e.target.value.toLowerCase();
    if (groupIdentifier) {
      setGroupIdentifierStatus(FormValidationStates.Validating);
      let groupIdentifierStatus = FormValidationStates.Error;
      let groupIdentifierError =
        getGroupIdentifierErrorMessage(groupIdentifier);
      if (validateUniqueness && groupIdentifierError === null) {
        const resp = await searchGroupIdentifier("GroupIdentifier", [
          groupIdentifier,
        ]);
        if (resp.payload && resp.payload.data) {
          const taken = resp.payload.data.find(
            (o) => o.value === groupIdentifier
          ).isTaken;
          if (taken) {
            groupIdentifierError = "The Group identifier is already in use.";
          }
        }
      }
      if (groupIdentifierError === null) {
        groupIdentifierStatus = FormValidationStates.Success;
      }
      setGroupIdentifierStatus(groupIdentifierStatus);
      setGroupIdentifierError(groupIdentifierError);
    }
  };

  return (
    <Form onSubmit={handleSubmitWrapper}>
      <Form.Item
        {...formItemLayout}
        label="Group identifier"
        hasFeedback
        validateStatus={groupIdentifierStatus}
        help={helpText(groupIdError)}
        rules={[
          {
            required: true,
          },
        ]}
        required
      >
        {createFieldDecorator("groupIdentifier", {
          validateTrigger: "onBlur",
          rules: [
            {
              required: true,
              message: "Please input the group's identifier!",
            },
          ],
        })(
          <Input
            id="groupIdentifierInput"
            prefix={<UserOutlined className={styles.normalIcon} />}
            placeholder="my-group"
            onBlur={(event) => {
              event.target.value = event.target.value.toLowerCase();
              validateGroupIdentifier(event, true);
            }}
            onChange={(event) => {
              event.target.value = event.target.value.toLowerCase();
              validateGroupIdentifier(event, false);
            }}
          />
        )}
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label="Group display name"
        hasFeedback
        validateStatus={displayNameStatus}
        help={helpText(displayNameError)}
        rules={[
          {
            required: true,
          },
        ]}
        required
      >
        {createFieldDecorator("displayName", {
          rules: [
            {
              required: true,
              message: "Please input the group's name!",
            },
          ],
        })(
          <Input
            id="groupDisplayNameInput"
            placeholder="My group"
            onBlur={validateGroupDisplayName}
          />
        )}
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label="Description"
        validateStatus={descriptionError ? "error" : ""}
        help={descriptionError || ""}
        rules={[
          {
            required: true,
          },
        ]}
        required
      >
        {createFieldDecorator("description", {
          rules: [
            {
              required: true,
              message: "Please input the group's description!",
            },
            {
              pattern: /\S+/,
              message: "Description cannot be only spaces!",
            },
          ],
        })(
          <Input.TextArea
            id="groupDescriptionInput"
            prefix={<FormOutlined className={styles.normalIcon} />}
            placeholder="Group description"
          />
        )}
      </Form.Item>
      <Form.Item {...formItemLayout} label="Administrator group">
        {createFieldDecorator(
          "administratorsId",
          {}
        )(<AssignAdministratorGroup />)}
      </Form.Item>
      {!dynamic && (
        <Form.Item
          {...formItemLayout}
          label={
            <span>
              Purge non active users{" "}
              <TooltipInfoIcon
                title="When identities become inactive, e.g. they leave CERN, they will be removed from this group if this checkbox is ticked."
                size={"small"}
              />
            </span>
          }
        >
          {createFieldDecorator(
            "removeNonActiveMembers",
            {
              valuePropName: "checked",
            },
            false
          )(<Checkbox />)}
        </Form.Item>
      )}

      <>
        <Form.Item
          {...formItemLayout}
          label={
            <span>
              Resource category{" "}
              <TooltipInfoIcon
                title="Test - will be removed in a short time; Official - will be reassigned to your supervisor in case of departure; Personal - will be deleted in case of departure."
                size={"small"}
              />
            </span>
          }
        >
          {createFieldDecorator(
            "resourceCategory",
            {},
            resourceCategories.Test
          )(<ResourceCategoryRadioGroup />)}
        </Form.Item>
        <Form.Item {...formItemLayout} label="Expiration date">
          {createFieldDecorator(
            "validityLimit",
            {},
            moment().add(12, "M")
          )(
            <DatePicker
              disabledDate={(c) => c.isBefore(moment())}
              placeholder="dd-mm-yyyy"
              format={"DD-MM-YYYY"}
            />
          )}
        </Form.Item>
        {!dynamic && (
          <Form.Item {...formItemLayout} label="Self-subscription policy">
            {createFieldDecorator(
              "selfSubscriptionType",
              {},
              selfSubscriptionTypes.Closed
            )(<SelfSubscriptionRadioGroup />)}
          </Form.Item>
        )}
        {!dynamic &&
          form.getFieldValue("selfSubscriptionType") !==
            selfSubscriptionTypes.Closed && (
            <Form.Item {...formItemLayout} label="Approval required">
              {createFieldDecorator(
                "approvalRequired",
                {
                  valuePropName: "checked",
                },
                false
              )(<Checkbox />)}
            </Form.Item>
          )}
        <Form.Item {...formItemLayout} label="Privacy policy">
          {createFieldDecorator(
            "privacyType",
            {},
            privacyTypes.Admins
          )(<PrivacyRadioGroup />)}
          <br />
          <i style={{ color: "red" }}>
            The e-group memberships will be shared with other applications that
            may not provide the same level of confidentiality.
          </i>
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          help={""}
          label="Synchronize with E-groups?"
        >
          {createFieldDecorator(
            "syncEgroups",
            {
              valuePropName: "checked",
            },
            false
          )(<Checkbox />)}
        </Form.Item>
        {getDynamicGroupsContent()}
        {createButtons(errors)}
      </>
    </Form>
  );
};

GroupCreationForm.propTypes = {
  groupScopes: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  form: PropTypes.shape({
    isFieldTouched: PropTypes.func,
    getFieldError: PropTypes.func,
    validateFields: PropTypes.func,
    getFieldDecorator: PropTypes.func,
    getFieldValue: PropTypes.func,
  }),
  dynamic: PropTypes.bool.isRequired,
  onSelectOwner: PropTypes.func,
  searchGroupIdentifier: PropTypes.func.isRequired,
};

const WrappedGroupCreationForm = Form.create({ name: "group_creation_form" })(
  GroupCreationForm,
  searchGroupIdentifier
);

export default connect(
  (state) => ({
    groupScopes: state.groupScope,
  }),
  {
    searchGroupIdentifier,
  }
)(WrappedGroupCreationForm);
