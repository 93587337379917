import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Button, Modal, Label, Input } from "semantic-ui-react";
import { Table } from "antd";
import { Message } from "semantic-ui-react";
import { CheckOutlined } from "@ant-design/icons";
import { searchForPerson, searchForGroup } from "../../actions/api";
import _ from "lodash";
import { validateEmail } from "../../util";

const AddMemberSearchModal = ({
  onSelect,
  searchValue,
  onCreateByEmail,
  isReplicaGroup,
  isModalOpen,
  onModalClose,
}) => {
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const [searchIsLoading, setSearchIsLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    setVisible(isModalOpen);
  }, [isModalOpen]);

  const modalColumns = [
    {
      title: "Identifier",
      dataIndex: "displayName",
      render: (text, record) => {
        if (record.displayName && record.upn) {
          return `${record.displayName} (${record.upn})`;
        } else if (record.groupIdentifier) {
          return record.groupIdentifier;
        }
        return text;
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (text, record) => {
        if (record.displayName && record.upn) {
          return record.type;
        } else if (record.groupIdentifier) {
          return "Group";
        }
        return text;
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      render: (_, record) => (
        <Button
          id="recordButton"
          type="primary"
          positive
          onClick={() => handleSelect(record)}
        >
          <CheckOutlined />
        </Button>
      ),
    },
  ];

  const clearInputs = () => {
    setEmailAddress("");
    setDataSource([]);
  };

  const handleSelect = (record) => {
    setVisible(false);
    if (onModalClose) {
      onModalClose();
    }
    onSelect(record);
    clearInputs();
  };

  const openUpdateModal = (e) => {
    e.preventDefault();
    setVisible(true);
    clearInputs();
  };

  const handleCancel = () => {
    setVisible(false);
    if (onModalClose) {
      onModalClose();
    }
    clearInputs();
  };

  const debouncedSearch = useCallback(
    _.debounce((value) => {
      setEmailAddress(value);
      if (value.length >= 2) {
        setSearchIsLoading(true);

        const identitiesPromise = dispatch(searchForPerson(value));
        const groupsPromise = dispatch(
          searchForGroup({
            filter: `groupIdentifier:Contains:${value}`,
            sort: "groupIdentifier",
          })
        );

        Promise.all([identitiesPromise, groupsPromise])
          .then(([identitiesResult, groupsResult]) => {
            setDataSource([
              ...identitiesResult.payload,
              ...groupsResult.payload.data,
            ]);
            setSearchIsLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            setSearchIsLoading(false);
          });
      }
    }, 500),
    [dispatch]
  );

  const onInputChange = (e, { value }) => {
    debouncedSearch(value);
  };

  useEffect(() => {
    if (searchValue && visible) {
      debouncedSearch(searchValue);
    }
  }, [searchValue, visible, debouncedSearch]);

  const onSubmitEmailText = async (e) => {
    e.preventDefault();
    setSearchIsLoading(true);
    await onCreateByEmail(emailAddress);
    setSearchIsLoading(false);
    setVisible(false);
    if (onModalClose) {
      onModalClose();
    }
    clearInputs();
  };

  const renderCreatePlaceholder = () => {
    const cernMailFormat = /^(.*)(@cern\.ch)$/;
    const isCernAddress = cernMailFormat.test(
      String(emailAddress).toLowerCase()
    );

    if (!validateEmail(emailAddress) || isCernAddress) {
      return (
        <Label
          content="If you want to add a member by mail, write user's mail in the search. 
          The mail domain must be different than: @cern.ch"
          icon="mail"
        />
      );
    }

    return (
      <Message info>
        <Message.Header>
          Can't find the user you are looking for?
        </Message.Header>
        <Button
          id="submitButton"
          type="submit"
          onClick={onSubmitEmailText}
          disabled={
            validateEmail(emailAddress) === false ||
            searchIsLoading ||
            isCernAddress
          }
          style={{ marginTop: "0.4rem" }}
        >
          Add by email
        </Button>
      </Message>
    );
  };

  return (
    <>
      <Button
        id="addButton"
        onClick={openUpdateModal}
        icon={"add"}
        content="Add Member"
        disabled={isReplicaGroup}
        color={isReplicaGroup ? "grey" : "green"}
      ></Button>
      <Modal
        style={{ minWidth: "90%" }}
        closeIcon
        open={visible}
        onClose={handleCancel}
      >
        <Modal.Header>Add a user or group</Modal.Header>
        <Modal.Content>
          {renderCreatePlaceholder()}
          <p style={{ marginTop: "0.6rem" }}>Type at least 2 characters of:</p>
          <ul>
            <li>
              {" "}
              the identity's email address, login or name (first or last name)
            </li>
            <li> the group's name or identifier</li>
          </ul>
          <Input
            autoFocus
            fluid
            placeholder="Identity or group search criteria"
            defaultValue={searchValue || ""}
            onChange={onInputChange}
            style={{ minWidth: "50%", textAlign: "center" }}
          />
          <Table
            style={{ marginTop: "10px" }}
            loading={searchIsLoading}
            rowKey="id"
            pagination={{
              pageSize: 20,
            }}
            columns={modalColumns}
            dataSource={dataSource}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};

AddMemberSearchModal.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onCreateByEmail: PropTypes.func.isRequired,
  isReplicaGroup: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool,
  onModalClose: PropTypes.func,
};

export default AddMemberSearchModal;
