import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Input, notification, Button as AntButton } from "antd";
import { Segment, Header, Button, Icon, Modal } from "semantic-ui-react";
import { ContentTable } from "@authzsvc/common-react-components";
import { LoadingWrapper } from "@authzsvc/common-react-components";

const GroupMembersView = ({
  title,
  titleIcon,
  searchValue,
  setSearchValue,
  columns,
  dataSource,
  total,
  extraButtons,
  loading,
  onDelete,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [filteredDataSource, setFilteredDataSource] = useState(dataSource);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState([]); // State to store detailed information

  useEffect(() => {
    setFilteredDataSource(dataSource);
  }, [dataSource]);

  const changeInput = (e) => {
    const searchText = e.target.value;
    setSearchValue(searchText);
    if (searchText === "") {
      setFilteredDataSource(dataSource);
    } else if (filteredDataSource) {
      const searchDataSourceFiltered = dataSource.filter(
        (item) =>
          (item.memberIdentity &&
            item.memberIdentity.displayName
              .toLowerCase()
              .includes(searchText.toLowerCase())) ||
          (item.memberGroup &&
            item.memberGroup.displayName
              .toLowerCase()
              .includes(searchText.toLowerCase()))
      );
      setFilteredDataSource(searchDataSourceFiltered);
    }
  };

  const showDeleteModal = () => {
    setIsModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    setIsModalOpen(false);
    setLoadingDelete(true);
    await onDelete(selectedRows);
    setSelectedRows([]);
    setSelectedDetails([]);
    setSearchValue("");
    setLoadingDelete(false);

    notification["success"]({
      message: "Members removed successfully!",
    });
  };

  const handleCancelDelete = () => {
    setIsModalOpen(false);
  };

  const clear = async () => {
    setSearchValue("");
    setFilteredDataSource(dataSource);
  };

  const onRowSelection = (selectedRowKeys) => {
    const selectedDetails = selectedRowKeys.map((key) => {
      const row = dataSource.find(
        (item) =>
          (item.memberIdentity && item.memberIdentity.id === key) ||
          (item.memberGroup && item.memberGroup.id === key)
      );

      return {
        id: row.memberIdentity ? row.memberIdentity.id : row.memberGroup.id,
        type: row.memberIdentity ? "identity" : "group",
        displayName: row.memberIdentity
          ? `${row.memberIdentity.displayName} (${row.memberIdentity.upn})`
          : row.memberGroup.groupIdentifier,
      };
    });

    setSelectedRows(selectedDetails);
    setSelectedDetails(selectedDetails.map((detail) => detail.displayName)); // Collect detailed information
  };

  const rowSelection = {
    onChange: onRowSelection,
    getCheckboxProps: (record) => ({
      name: record.displayName,
    }),
  };

  const hasSelections = selectedRows.length > 0;

  const getRowKey = (record) => {
    if (record.memberIdentity) {
      return record.memberIdentity.id;
    } else if (record.memberGroup) {
      return record.memberGroup.id;
    }
  };

  return (
    <Segment color="blue">
      <LoadingWrapper loading={loading}>
        <Header size="small">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              {titleIcon && <Icon size="large" name={titleIcon} />}
              {title}
              {total > 0 && <> (Total: {total}) </>}
            </div>

            {extraButtons}
          </div>
        </Header>
        <Segment basic>
          <Input.Group compact>
            <Input.Search
              id="searchTextInput"
              placeholder="Identity or group search criteria"
              value={searchValue}
              width={70}
              style={{
                float: "left",
                display: "inline-block",
                maxWidth: "80%",
              }}
              enterButton
              onChange={changeInput}
              suffix={
                <AntButton
                  loading={loadingDelete}
                  disabled={!searchValue}
                  onClick={clear}
                  style={{
                    display: "inline-block",
                    backgroundColor: !searchValue && "transparent",
                    border: "none",
                    padding: "0",
                  }}
                  type="danger"
                  icon="close"
                  shape="circle"
                  size="small"
                />
              }
            />
          </Input.Group>
        </Segment>
        <Segment basic>
          <ContentTable
            loading={loading}
            rowKey={getRowKey}
            pagination={{
              showSizeChanger: true,
              hideOnSinglePage: false,
              pageSizeOptions: ["10", "20", "50", "100"],
            }}
            columns={columns}
            dataSource={filteredDataSource}
            rowSelection={rowSelection}
          />
        </Segment>
        <Segment basic>
          <Button
            id="deleteSelectionsButton"
            loading={loadingDelete}
            disabled={!hasSelections}
            onClick={showDeleteModal}
            negative
            icon="trash alternate"
          />
        </Segment>

        <Modal open={isModalOpen} onClose={handleCancelDelete} size="small">
          <Header icon="trash" content="Confirm Delete" />
          <Modal.Content>
            <p>Are you sure you want to delete the following items?</p>
            <ul>
              {selectedDetails.map((detail, index) => (
                <li key={index}>{detail}</li>
              ))}
            </ul>
          </Modal.Content>
          <Modal.Actions>
            <Button color="red" onClick={handleCancelDelete}>
              <Icon name="remove" /> No
            </Button>
            <Button color="green" onClick={handleConfirmDelete}>
              <Icon name="checkmark" /> Yes
            </Button>
          </Modal.Actions>
        </Modal>
      </LoadingWrapper>
    </Segment>
  );
};

GroupMembersView.propTypes = {
  title: PropTypes.string.isRequired,
  titleIcon: PropTypes.string,
  columns: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  dataSource: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  extraButtons: PropTypes.object.isRequired,
};

export default GroupMembersView;
