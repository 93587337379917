import { errorAction, successAction, types } from "@authzsvc/api-lib";
import { notification } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import {
  subscribeCurrentIdentityToGroups,
  unsubscribeCurrentIdentityFromGroups,
  getCurrentIdentityGroups,
  getMyRequests,
} from "../../actions/api";
import { groupsUserRoleIdentifier } from "../../config";
import { GroupSyncTypes } from "../../constants/enums";
import { userTokenHasRole } from "../../selectors";

class GroupSubscriptionAction extends Component {
  state = {
    loading: false,
  };
  handleUnsubscribe = async () => {
    this.setState({ loading: true });
    const response = await this.props.unsubscribeCurrentIdentityFromGroups([
      this.props.groupId,
    ]);
    if (
      response.type ===
      errorAction(types.UNSUBSCRIBE_CURRENT_IDENTITY_FROM_GROUPS)
    ) {
      notification.error({
        message: response?.payload?.title || "Sorry, an error occurred",
        description:
          response?.payload?.data?.message || "Please try again later",
      });
    } else if (
      response.type ===
      successAction(types.UNSUBSCRIBE_CURRENT_IDENTITY_FROM_GROUPS)
    ) {
      this.props.toggleSubscription();
      notification.success({
        message: "Successfully unsubscribed from group",
      });
    }
    this.setState({ loading: false });
    this.props.getCurrentIdentityGroups();
  };

  displaySubscriptionMessage = (data) => {
    if (data["request_id"] !== null) {
      notification.warning({
        message: data.title ?? "We're sorry! An error has occurred",
        description: data.data.message ?? "",
      });
    } else {
      this.props.toggleSubscription();
      notification.success({
        message: "Successfully subscribed to group",
      });
    }
  };

  handleSubscribe = async () => {
    this.setState({ loading: true });
    const identityMembershipObject = [
      {
        id: this.props.groupId,
        comment: this.props.reason,
      },
    ];
    const response = await this.props.subscribeCurrentIdentityToGroups(
      identityMembershipObject
    );
    if (response) {
      this.setState({ loading: false });
      this.props.getCurrentIdentityGroups().then(() => {
        if (response && response.payload) {
          this.displaySubscriptionMessage(response.payload);
          if (this.props.closeModal) {
            this.props.closeModal();
          }
        }
      });

      // Update the list of requests in Redux
      this.props.getMyRequests();
    }
  };

  render() {
    const { loading } = this.state;

    if (this.props.belongsToGroup) {
      return (
        <Button
          id="unsubscribeButton"
          color="red"
          onClick={this.handleUnsubscribe}
          loading={loading}
          disabled={this.groupIsSlave() || this.props.disabled}
        >
          Unsubscribe
        </Button>
      );
    } else {
      return (
        <Button
          id="subscribeButton"
          type="primary"
          color="blue"
          onClick={this.handleSubscribe}
          loading={loading}
          disabled={
            !this.props.isGroupUser ||
            this.groupIsSlave() ||
            this.props.disabled
          }
        >
          Subscribe
        </Button>
      );
    }
  }

  groupIsSlave = () => {
    return (
      this.props.syncType !== GroupSyncTypes.Master &&
      this.props.syncType !== GroupSyncTypes.NoSync
    );
  };
}

GroupSubscriptionAction.propTypes = {
  groupId: PropTypes.string.isRequired,
  syncType: PropTypes.string.isRequired,
  size: PropTypes.string,
};

export default connect(
  (state) => ({
    groupMemberships: state.groupMembership.direct,
    isGroupUser: userTokenHasRole(state, groupsUserRoleIdentifier),
  }),
  {
    unsubscribeCurrentIdentityFromGroups,
    subscribeCurrentIdentityToGroups,
    getCurrentIdentityGroups,
    getMyRequests,
  }
)(GroupSubscriptionAction);
