import React from "react";
import { TitleContext } from "../../hooks";

/**
 * Function to update the pages titles manually.
 * Constants formatting in the web titles:
 *   ( basic title [category] - action )
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
function TitleProvider({ children }) {
  const baseTitle = "CERN Groups Portal";
  const [category, setCategory] = React.useState("");
  const [action, setAction] = React.useState("");

  // reset action field whenever category changes
  React.useEffect(() => {
    setAction("");
  }, [category]);

  return (
    <TitleContext.Provider
      value={{
        title: `${baseTitle} [${category}] ${action}`,
        setCategory,
        setAction,
      }}
    >
      {children}
    </TitleContext.Provider>
  );
}

export default TitleProvider;
