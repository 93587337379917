import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { types as at } from "@authzsvc/api-lib";

import { getMyRequests, getRequestsToApprove } from "../../actions/api";
import { isApiCallLoading } from "../../selectors";
import { Requests } from "@authzsvc/common-react-components";

const RequestsWrapper = ({
  // data
  myRequests,
  loadingMyRequests,
  loadingToApprove,
  toApprove,
  history,
  // actions
  getMyRequests,
  getRequestsToApprove,
}) => {
  useEffect(() => {
    getMyRequests();
    getRequestsToApprove();
  }, [getRequestsToApprove, getMyRequests]);

  const handleSelect = (request) => {
    if (request) {
      history.push(`/requests/${request.id}`);
    }
  };

  return (
    <Requests
      onSelect={(request) => handleSelect(request)}
      myRequests={myRequests}
      toApprove={toApprove}
      loadingToApprove={loadingToApprove}
      loadingMyRequests={loadingMyRequests}
    />
  );
};

export default withRouter(
  connect(
    (state, router) => ({
      history: router.history,
      loadingMyRequests: isApiCallLoading(state, at.GET_REQUESTS),
      loadingToApprove: isApiCallLoading(state, at.GET_REQUESTS_TO_APPROVE),
      myRequests: state.requests.my,
      toApprove: state.requests.toApprove,
    }),
    { getMyRequests, getRequestsToApprove }
  )(RequestsWrapper)
);
