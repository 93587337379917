import React from "react";
import PropTypes from "prop-types";
import { Segment, Header, Radio } from "semantic-ui-react";

const MailDeliveryNotification = ({
  deliveryFailureReport,
  canModifyGroup,
  updateGroupMailProperties,
}) => {
  const handleDeliveryIssueNotificationRadioChange = (_, { value }) => {
    updateGroupMailProperties({ deliveryFailureReport: value });
  };

  return (
    <Segment color="blue">
      <Header size="medium">
        Who will be notified in case of mail delivery problems?
      </Header>
      <Segment basic>
        <Radio
          label="Mail Sender (default)"
          name="deliveryIssueNotification"
          value="ReportToSender"
          disabled={!canModifyGroup}
          checked={deliveryFailureReport === "ReportToSender"}
          onChange={handleDeliveryIssueNotificationRadioChange}
        />
        <br />
        <Radio
          label="Group Owner"
          name="deliveryIssueNotification"
          value="ReportToOwner"
          disabled={!canModifyGroup}
          checked={deliveryFailureReport === "ReportToOwner"}
          onChange={handleDeliveryIssueNotificationRadioChange}
        />
        <br />
        <Radio
          label="No Notification"
          name="deliveryIssueNotification"
          value="NoReport"
          disabled={!canModifyGroup}
          checked={deliveryFailureReport === "NoReport"}
          onChange={handleDeliveryIssueNotificationRadioChange}
        />
      </Segment>
    </Segment>
  );
};

MailDeliveryNotification.propTypes = {
  deliveryFailureReport: PropTypes.string,
  canModifyGroup: PropTypes.bool,
  updateGroupMailProperties: PropTypes.func,
};

export default MailDeliveryNotification;
