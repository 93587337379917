import React from "react";
import { Dropdown } from "semantic-ui-react";
import { Link } from "react-router-dom";
import * as cfg from "../../config";
import RequestsHeaderButton from "./RequestsHeaderButton";

const HeaderDropdownItems = () => (
  <>
    <Dropdown.Divider />
    <Dropdown.Item
      as={Link}
      to="/group/memberships/my"
      content={
        <>
          <span>Group Memberships</span>
        </>
      }
    />
    <br />
    <RequestsHeaderButton />
    <br />
    <Dropdown.Divider />
    <Dropdown.Item text="Help" icon="help" href={cfg.sidebar_help} />
    <br />
    <Dropdown.Item
      text="Applications"
      icon="code"
      href={cfg.sidebar_applications}
    />
    <br />
    <Dropdown.Item
      text="My Identity"
      icon="user"
      href={cfg.sidebar_identities}
    />
    <br />
    <Dropdown.Item
      text="Resources"
      icon="computer"
      href={cfg.sidebar_resources}
    />
  </>
);

export default HeaderDropdownItems;
