import { apiActionCreator, mergeEntities, types } from "@authzsvc/api-lib";
import { DEFAULT_GROUP_TABLE_QUERY_FIELDS } from "../constants";

export const searchForGroup = (queryFilter) => async (dispatch) => {
  return await dispatch(apiActionCreator(types.SEARCH_GROUP, { queryFilter }));
};

export const getIdentityById = (id, queryFilter) => async (dispatch) =>
  await dispatch(
    apiActionCreator(types.GET_IDENTITY_BY_ID, { id: id, queryFilter })
  );

export const getAccountById = (id) => (dispatch) => {
  dispatch(apiActionCreator(types.GET_ACCOUNT_BY_ID, { id: id }));
};

export const getApplicationById = (applicationid) => async (dispatch) =>
  await dispatch(
    apiActionCreator(types.GET_APPLICATION, { applicationid: applicationid })
  );

export const selectGroupById = (id) => async (dispatch) =>
  await dispatch(apiActionCreator(types.GET_GROUP_BY_ID, { id: id }));

export const selectAdminGroupById = (id) => async (dispatch) =>
  await dispatch(apiActionCreator(types.GET_ADMIN_GROUP_BY_ID, { id: id }));

export const resolveAuditsForEntity = (id, queryFilter) => async (dispatch) =>
  await dispatch(
    apiActionCreator(types.RESOLVE_AUDITS_FOR_ENTITY, { id, queryFilter })
  );

export const getGroupScopes = (queryFilter) => async (dispatch) =>
  await dispatch(apiActionCreator(types.GET_GROUP_SCOPES, { queryFilter }));

export const getScopesForGroup = (id) => async (dispatch) =>
  await dispatch(apiActionCreator(types.GET_SCOPES_FOR_GROUP, { id }));

export const addGroupToScope = (groupId, scopeId) => async (dispatch) =>
  await dispatch(
    apiActionCreator(types.ADD_GROUP_TO_SCOPE, { groupId, scopeId })
  );

export const removeGroupFromScope = (groupId, scopeId) => async (dispatch) =>
  await dispatch(
    apiActionCreator(types.REMOVE_GROUP_FROM_SCOPE, { groupId, scopeId })
  );

export const getIdentity = () => async (dispatch) =>
  await dispatch(apiActionCreator(types.GET_IDENTITY, {}));

export const getMyGroups = () => async (dispatch) => {
  const queryFilter = { field: DEFAULT_GROUP_TABLE_QUERY_FIELDS };
  return await dispatch(apiActionCreator(types.GET_MY_GROUPS, { queryFilter }));
};

export const resolveIdentity = (id) => async (dispatch) =>
  await dispatch(apiActionCreator(types.RESOLVE_IDENTITY, { identityid: id }));

export const searchGroupIdentifier =
  (identifierName, groupIdentifiers) => async (dispatch) => {
    return await dispatch(
      apiActionCreator(types.SEARCH_GROUP_IDENTIFIER, {
        identifierName: identifierName,
        identifierValues: groupIdentifiers,
      })
    );
  };

export const createStaticGroup = (data) => async (dispatch) =>
  await dispatch(apiActionCreator(types.CREATE_STATIC_GROUP, data));

export const updateGroup = (groupId, data) => async (dispatch) =>
  await dispatch(
    apiActionCreator(types.UPDATE_GROUP, {
      id: groupId,
      group: data,
    })
  );

export const getGroupsIOwn =
  (queryFilter = { field: DEFAULT_GROUP_TABLE_QUERY_FIELDS }) =>
  async (dispatch) => {
    return await dispatch(
      apiActionCreator(types.GET_GROUPS_I_OWN, { queryFilter })
    );
  };

export const getGroupMemberIdentities =
  (groupId, queryFilter) => async (dispatch) => {
    return await dispatch(
      apiActionCreator(types.GET_GROUP_MEMBER_IDENTITIES, {
        id: groupId,
        queryFilter,
      })
    );
  };

export const getGroupMemberIdentitiesWithComments =
  (groupId, queryFilter) => async (dispatch) => {
    return await dispatch(
      apiActionCreator(types.GET_GROUP_MEMBER_IDENTITIES_WITH_COMMENTS, {
        id: groupId,
        queryFilter,
      })
    );
  };

export const updateGroupMemberIdentityComment =
  (groupId, identityMemberId, comment) => async (dispatch) => {
    return await dispatch(
      apiActionCreator(types.UPDATE_GROUP_MEMBER_IDENTITY_COMMENT, {
        groupId,
        identityMemberId,
        comment,
      })
    );
  };

export const getGroupMemberGroups =
  (groupId, queryFilter) => async (dispatch) =>
    await dispatch(
      apiActionCreator(types.GET_GROUP_MEMBER_GROUPS, { groupId, queryFilter })
    );

export const getGroupMemberGroupsWithComments =
  (groupId, queryFilter) => async (dispatch) =>
    await dispatch(
      apiActionCreator(types.GET_GROUP_MEMBER_GROUPS_WITH_COMMENTS, {
        groupId,
        queryFilter,
      })
    );

export const updateGroupMemberGroupComment =
  (groupId, groupMemberId, comment) => async (dispatch) => {
    return await dispatch(
      apiActionCreator(types.UPDATE_GROUP_MEMBER_GROUP_COMMENT, {
        groupId,
        groupMemberId,
        comment,
      })
    );
  };

export const searchGroupMemberIdentity =
  (groupId, input, fields) => async (dispatch) => {
    const queries = [
      `displayName:StartsWith:${input}`,
      `lastName:StartsWith:${input}`,
      `firstName:StartsWith:${input}`,
      `upn:StartsWith:${input}`,
    ];

    const response = await Promise.all(
      queries.map((q) =>
        dispatch(
          getGroupMemberIdentities(groupId, {
            filter: q,
            field: fields,
          })
        )
      )
    );
    const jsonResponses = response.map((v) => v.payload);
    return mergeEntities(jsonResponses);
  };

export const searchGroupMemberGroups =
  (groupId, input, fields) => async (dispatch) => {
    const queries = [
      `groupIdentifier:StartsWith:${input}`,
      `displayName:StartsWith:${input}`,
    ];

    const response = await Promise.all(
      queries.map((q) =>
        dispatch(
          getGroupMemberGroups(groupId, {
            filter: q,
            field: fields,
          })
        )
      )
    );
    const jsonResponses = response.map((v) => v.payload);
    return mergeEntities(jsonResponses);
  };

export const searchForPerson = (name) => async (dispatch) => {
  return await dispatch(apiActionCreator(types.SEARCH_IDENTITY, { name }));
};

export const transferGroupOwner = (groupId, identityId) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.TRANSFER_GROUP_OWNER, {
      groupId,
      identityId,
    })
  );
};

export const deleteGroupById = (groupId) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.DELETE_GROUP_BY_ID, { groupId })
  );
};

export const addGroupMemberGroups =
  (groupId, memberGroupIds) => async (dispatch) => {
    return await dispatch(
      apiActionCreator(types.ADD_GROUP_MEMBER_GROUPS, {
        groupId,
        memberGroupIds,
      })
    );
  };

export const addGroupMemberIdentities =
  (groupId, identityIds) => async (dispatch) => {
    return await dispatch(
      apiActionCreator(types.ADD_GROUP_MEMBER_IDENTITIES, {
        groupId,
        identityIds,
      })
    );
  };

export const deleteGroupMemberGroups =
  (groupId, memberGroupIds) => async (dispatch) => {
    return await dispatch(
      apiActionCreator(types.DELETE_GROUP_MEMBER_GROUPS, {
        groupId,
        memberGroupIds,
      })
    );
  };

export const deleteGroupMemberIdentities =
  (groupId, identityIds) => async (dispatch) => {
    return await dispatch(
      apiActionCreator(types.DELETE_GROUP_MEMBER_IDENTITIES, {
        groupId,
        identityIds,
      })
    );
  };

export const getGroupMembersForTreeView =
  (groupId, fields = []) =>
  async (dispatch) => {
    return await dispatch(
      apiActionCreator(types.GET_GROUP_MEMBER_GROUPS_FOR_TREE_VIEW, {
        groupId,
        queryFilter: { field: fields },
      })
    );
  };

export const selectGroupParentForTreeView =
  (groupId, fields = []) =>
  async (dispatch) => {
    return await dispatch(
      apiActionCreator(types.GET_GROUP_PARENT_FOR_TREE_VIEW, {
        groupId,
        queryFilter: { field: fields },
      })
    );
  };

export const logout = () => async (dispatch) =>
  await dispatch(apiActionCreator(types.LOGOUT));

export const getDynamicCriteria = () => async (dispatch) =>
  await dispatch(apiActionCreator(types.GET_DYNAMIC_GROUP_CRITERIA));

// Requests
export const getMyRequests = () => (dispatch) => {
  dispatch(
    apiActionCreator(types.GET_REQUESTS, {
      queryFilter: {
        // Since we need an OR filter we have to do this on the frontend in RequestsWrapper
      },
    })
  );
};

export const getRequestsToApprove = () => (dispatch) => {
  dispatch(
    apiActionCreator(types.GET_REQUESTS_TO_APPROVE, {
      queryFilter: {
        // Since we need an OR filter we have to do this on the frontend in RequestsWrapper
      },
    })
  );
};

export const cancelRequest = (id) => async (dispatch) => {
  return await dispatch(apiActionCreator(types.CANCEL_REQUEST, { id: id }));
};

export const denyRequest = (id, reason) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.DENY_REQUEST, {
      id: id,
      reason: reason,
    })
  );
};

export const approveRequest = (id, reason) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.APPROVE_REQUEST, {
      id: id,
      reason: reason,
    })
  );
};

export const getGroupRoleAssignments = (groupId) => async (dispatch) =>
  await dispatch(
    apiActionCreator(types.GET_GROUP_ROLE_ASSIGNMENTS, { groupId })
  );

export const patchComputingGroup = (groupId, fields) => async (dispatch) =>
  await dispatch(
    apiActionCreator(types.PATCH_GROUP_BY_ID, { groupId, fields })
  );

export const getCurrentIdentityGroups = (queryParams) => async (dispatch) =>
  await dispatch(
    apiActionCreator(types.GET_CURRENT_IDENTITY_GROUPS, {
      queryParams,
    })
  );

export const subscribeCurrentIdentityToGroups = (body) => async (dispatch) =>
  await dispatch(
    apiActionCreator(types.SUBSCRIBE_CURRENT_IDENTITY_TO_GROUPS, {
      body,
    })
  );

export const unsubscribeCurrentIdentityFromGroups =
  (body) => async (dispatch) =>
    await dispatch(
      apiActionCreator(types.UNSUBSCRIBE_CURRENT_IDENTITY_FROM_GROUPS, {
        body,
      })
    );

export const getCurrentIdentityGroupsRecursively =
  (queryParams) => async (dispatch) =>
    await dispatch(
      apiActionCreator(types.GET_CURRENT_IDENTITY_GROUPS_RECURSIVELY, {
        queryParams,
      })
    );

export const getGroupMailPropertiesForGroup = (groupId) => async (dispatch) =>
  await dispatch(
    apiActionCreator(types.GET_GROUP_MAIL_PROPERTIES_FOR_GROUP, { groupId })
  );

export const updateGroupMailPropertiesForGroup =
  (groupId, body) => async (dispatch) =>
    await dispatch(
      apiActionCreator(types.UPDATE_GROUP_MAIL_PROPERTIES_FOR_GROUP, {
        groupId,
        body,
      })
    );

export const addGroupMailPropertiesIdentitiesAllowedToPost =
  (groupId, identityIds) => async (dispatch) =>
    await dispatch(
      apiActionCreator(
        types.ADD_GROUP_MAIL_PROPERTIES_IDENTITIES_ALLOWED_TO_POST,
        { groupId, identityIds }
      )
    );

export const deleteGroupMailPropertiesIdentitiesAllowedToPost =
  (groupId, identityIds) => async (dispatch) =>
    await dispatch(
      apiActionCreator(
        types.DELETE_GROUP_MAIL_PROPERTIES_IDENTITIES_ALLOWED_TO_POST,
        { groupId, identityIds }
      )
    );

export const addGroupMailPropertiesGroupsAllowedToPost =
  (groupId, groupIdentifiers) => async (dispatch) =>
    await dispatch(
      apiActionCreator(types.ADD_GROUP_MAIL_PROPERTIES_GROUPS_ALLOWED_TO_POST, {
        groupId,
        groupIdentifiers,
      })
    );

export const deleteGroupMailPropertiesGroupsAllowedToPost =
  (groupId, groupIds) => async (dispatch) =>
    await dispatch(
      apiActionCreator(
        types.DELETE_GROUP_MAIL_PROPERTIES_GROUPS_ALLOWED_TO_POST,
        { groupId, groupIds }
      )
    );

export const getGroupMembersCSV =
  (groupId, compatibilityMode) => async (dispatch) =>
    await dispatch(
      apiActionCreator(types.GET_GROUP_MEMBERS_CSV, {
        groupId,
        compatibilityMode,
      })
    );

export const putGroupMembersCSV =
  (groupId, compatibilityMode, replace, file) => async (dispatch) =>
    await dispatch(
      apiActionCreator(types.PUT_GROUP_MEMBER_CSV, {
        groupId,
        compatibilityMode,
        replace,
        file,
      })
    );

export const getMembershipRestriction = (groupId) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.GET_MEMBERSHIP_RESTRICTION, {
      enforcedGroupId: groupId,
    })
  );
};
