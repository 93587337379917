import PropTypes from "prop-types";
import { selectAdminGroupById } from "../../actions/api";

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Popup, Icon, Placeholder } from "semantic-ui-react";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import { types, errorAction } from "@authzsvc/api-lib";
import { showErrorNotification } from "../../util";

const PlaceholderLoading = ({ loading, children }) => {
  const placeholder = (
    <Placeholder fluid>
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder>
  );
  if (loading) {
    return placeholder;
  }
  return children;
};

/**
 * Gets the group based on an ID and displays its display name
 */
const AdminGroup = ({ adminId, selectAdminGroupById }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [group, setGroup] = useState(undefined);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      const groupResponse = await selectAdminGroupById(adminId);
      if (groupResponse && groupResponse.payload) {
        setGroup(groupResponse.payload.data);
      } else if (
        groupResponse.type === errorAction(types.GET_ADMIN_GROUP_BY_ID)
      ) {
        showErrorNotification(
          groupResponse.payload.title,
          groupResponse.payload.data.message
        );
      }
      setIsLoading(false);
    };
    loadData();
  }, [setIsLoading, adminId, setGroup, selectAdminGroupById]);

  return (
    <PlaceholderLoading loading={isLoading}>
      <>
        {group && (
          <Popup
            key={group.id}
            trigger={
              <NavLink to={`/group/${group.id}/details`}>
                <span style={{ cursor: "pointer" }}>{group.displayName}</span>
              </NavLink>
            }
            header={
              <h3>
                <Icon name="group" />
                {group.groupIdentifier}
              </h3>
            }
            content={
              <>
                <div>Name: {group.displayName}</div>
                <div>Description: {group.description}</div>
                <div>Blocked: {group.blocked ? "true" : "false"}</div>
              </>
            }
          />
        )}
      </>
    </PlaceholderLoading>
  );
};

AdminGroup.propTypes = {
  adminId: PropTypes.string.isRequired,
  selectAdminGroupById: PropTypes.func.isRequired,
};

export default withRouter(connect(null, { selectAdminGroupById })(AdminGroup));
