import React from "react";
import { Icon } from "semantic-ui-react";
import { Menu as AntMenu } from "antd";
import {
  UsergroupAddOutlined,
  TeamOutlined,
  AuditOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { keycloakAuthenticated } from "../../selectors";

const GroupActionsMenu = ({ authenticated }) => {
  return (
    <>
      {authenticated && (
        <div>
          <AntMenu mode="horizontal">
            <AntMenu.Item>
              <NavLink exact activeClassName="active" to="/">
                <Icon name="search" size="small" /> <span>Search Groups</span>
              </NavLink>
            </AntMenu.Item>
            <AntMenu.Item>
              <NavLink exact activeClassName="active" to="/groups/admin">
                <AuditOutlined />
                <span>All groups I own or manage</span>
              </NavLink>
            </AntMenu.Item>
            <AntMenu.Item>
              <NavLink exact activeClassName="active" to="/group/create">
                <TeamOutlined />
                <span>Create new static group</span>
              </NavLink>
            </AntMenu.Item>
            <AntMenu.Item>
              <NavLink exact activeClassName="active" to="/group/createDynamic">
                <UsergroupAddOutlined />
                <span>Create new dynamic group</span>
              </NavLink>
            </AntMenu.Item>
          </AntMenu>
        </div>
      )}
    </>
  );
};

export default connect((state) => ({
  authenticated: keycloakAuthenticated(state),
}))(GroupActionsMenu);
