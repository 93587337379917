import React from "react";
import PropTypes from "prop-types";
import { Popup, Icon } from "semantic-ui-react";

/**
 * Displays the owner passed in
 */
const Owner = ({ owner }) => (
  <>
    {owner && (
      <Popup
        key={owner.id}
        trigger={<span style={{ cursor: "pointer" }}>{owner.displayName}</span>}
        header={
          <h3>
            <Icon name="user outline" /> {owner.displayName}
          </h3>
        }
        content={
          <>
            <div>Main login: {owner.upn}</div>
            <div>Resource type: {owner.type}</div>
            <div>Blocked: {owner.blocked ? "true" : "false"}</div>
          </>
        }
      />
    )}
  </>
);

Owner.propTypes = {
  owner: PropTypes.object.isRequired,
};

export default Owner;
