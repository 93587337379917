import { types as at, errorAction, types } from "@authzsvc/api-lib";
import { notification } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Header, Icon, Segment, Button } from "semantic-ui-react";
import {
  addGroupMemberGroups,
  addGroupMemberIdentities,
  deleteGroupMemberGroups,
  deleteGroupMemberIdentities,
  getGroupMemberGroupsWithComments,
  getGroupMemberIdentitiesWithComments,
} from "../../actions/api";
import { getDefaultGroupMembersTableColumns } from "../../constants";
import AddMemberSearchModal from "../common/AddMemberSearchModal";

import GroupMembersView from "./members/GroupMembersView";
import CSVModal from "./members/CSVModal";
import { showErrorNotification } from "../../util";

const GroupMembers = ({
  // props
  match,
  selectedGroup,
  canManageGroup,
}) => {
  const dispatch = useDispatch();

  const [blocked, setBlocked] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isImportModalOpen, setImportModalOpen] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const getMembers = useCallback(async () => {
    setLoading(true);

    const identities = await dispatch(
      getGroupMemberIdentitiesWithComments(match.params.id)
    );
    const groups = await dispatch(
      getGroupMemberGroupsWithComments(match.params.id)
    );

    const isErrorAction =
      identities.type ===
        errorAction(types.GET_GROUP_MEMBER_IDENTITIES_WITH_COMMENTS) ||
      groups.type === errorAction(types.GET_GROUP_MEMBER_GROUPS_WITH_COMMENTS);

    if (isErrorAction) {
      if (
        identities?.payload?.data?.status === 403 ||
        groups?.payload?.data?.status === 403
      ) {
        setBlocked(true);
      } else {
        const title =
          identities?.payload?.title ||
          groups?.payload?.title ||
          "An error occurred fetching the members";
        const description =
          identities?.payload?.data?.message ||
          groups?.payload?.data?.message ||
          "Please try again later";

        notification.error({
          message: title,
          description: description,
        });
      }
    } else {
      if (identities.payload && groups.payload) {
        setMembers([...identities.payload.data, ...groups.payload.data]);
      }
    }

    setIsInitialized(true);
    setLoading(false);
  }, [dispatch, match.params.id]);

  useEffect(() => {
    getMembers();
  }, [getMembers]);

  const handleErrorActions = (result, actionType) => {
    if (result.type === errorAction(actionType)) {
      showErrorNotification(result.payload.title, result.payload.data.message);

      return false;
    }

    return true;
  };

  const onDeleteMembers = async (selectedRows) => {
    const { id: groupId } = match.params;

    const deleteActions = [
      {
        type: "identity",
        action: deleteGroupMemberIdentities,
        actionType: types.DELETE_GROUP_MEMBER_IDENTITIES,
      },
      {
        type: "group",
        action: deleteGroupMemberGroups,
        actionType: types.DELETE_GROUP_MEMBER_GROUPS,
      },
    ];

    for (const { type, action, actionType } of deleteActions) {
      const ids = selectedRows
        .filter((row) => row.type === type)
        .map((row) => row.id);
      if (ids.length > 0) {
        const result = await dispatch(action(groupId, ids));
        if (!handleErrorActions(result, actionType)) {
          return;
        }
      }
    }

    await getMembers();
  };

  const handleAddMember = async (newMember) => {
    const { id: groupId } = match.params;

    let identity, group;
    if (newMember.displayName && newMember.upn) {
      identity = await dispatch(
        addGroupMemberIdentities(groupId, [{ id: newMember.id }])
      );
    } else if (newMember.groupIdentifier) {
      group = dispatch(addGroupMemberGroups(groupId, [{ id: newMember.id }]));
    }

    const isErrorAction =
      identity?.type === errorAction(types.ADD_GROUP_MEMBER_IDENTITIES) ||
      group?.type === errorAction(types.ADD_GROUP_MEMBER_GROUPS);

    if (isErrorAction) {
      const title =
        identity?.payload?.title ||
        group?.payload?.title ||
        "An error occurred adding the member";
      const description =
        identity?.payload?.data?.message ||
        group?.payload?.data?.message ||
        "Please try again later";

      notification.error({
        message: title,
        description: description,
      });

      return;
    }

    await getMembers();
  };

  const handleCreateByEmail = async (email) => {
    const { id: groupId } = match.params;
    const identityIds = [{ id: email }];
    const createByEmailResponse = await dispatch(
      addGroupMemberIdentities(groupId, identityIds)
    );
    if (
      createByEmailResponse &&
      createByEmailResponse.type === errorAction(at.ADD_GROUP_MEMBER_IDENTITIES)
    ) {
      showErrorNotification(
        createByEmailResponse.payload.title,
        createByEmailResponse.payload.data.message
      );
    }
    await getMembers();
  };

  if (blocked) {
    return (
      <Segment placeholder>
        <Header icon>
          <Icon name="lock" />
          You don't have the access rights to view group members
        </Header>
      </Segment>
    );
  }

  const isReplicaGroup =
    selectedGroup?.syncType === "SlaveWithPlaceholders" ||
    selectedGroup?.syncType === "Slave";

  const columns = getDefaultGroupMembersTableColumns(selectedGroup.id);

  return (
    <>
      {canManageGroup && isReplicaGroup && (
        <Segment color="red">
          <p>
            <Icon name="exclamation circle" />
            You cannot modify the memberships to this group because it is being
            synchronized from the legacy EGroups service
          </p>
          <a
            href={`https://e-groups.cern.ch/e-groups/Egroup.do?egroupName=${selectedGroup.groupIdentifier}`}
          >
            Access here to modify the memberships of this group
          </a>
        </Segment>
      )}
      <GroupMembersView
        title="Group members"
        titleIcon="user"
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        columns={columns}
        dataSource={members}
        total={members.length}
        group={selectedGroup}
        extraButtons={
          <div
            style={{
              gap: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {!selectedGroup.dynamic && (
              <AddMemberSearchModal
                title="Add an user or group"
                searchValue={searchValue}
                isReplicaGroup={isReplicaGroup}
                onSelect={handleAddMember}
                onCreateByEmail={handleCreateByEmail}
              />
            )}
            <CSVModal
              isModalOpen={isImportModalOpen}
              onClose={() => setImportModalOpen(false)}
              group={selectedGroup}
              getMembers={getMembers}
              isDownload={isDownload}
            />
            <Button
              id="export_csv"
              onClick={() => {
                setImportModalOpen(true);
                setIsDownload(true);
              }}
              icon={"download"}
              content="Export"
              disabled={members.length === 0}
              color={"green"}
            />
            {!selectedGroup.dynamic && (
              <Button
                id="mergeButton"
                onClick={() => {
                  setImportModalOpen(true);
                  setIsDownload(false);
                }}
                content="Import"
                icon={"upload"}
                color={"blue"}
                style={{ marginRight: "0.5rem" }}
              />
            )}
          </div>
        }
        loading={loading || !isInitialized}
        onDelete={onDeleteMembers}
        getMembers={getMembers}
      />
    </>
  );
};

export default GroupMembers;
