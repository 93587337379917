import React, { Component } from "react";
import { Tree } from "antd";
import { BranchesOutlined } from "@ant-design/icons";
import { Segment, Header } from "semantic-ui-react";
import { connect } from "react-redux";
import styles from "./Group.module.css";
import {
  getGroupMembersForTreeView,
  selectGroupParentForTreeView,
} from "../../actions/api";

import TooltipInfoIcon from "../common/TooltipInfoIcon";
import { LoadingWrapper } from "@authzsvc/common-react-components";

const { TreeNode } = Tree;

class GroupTreeView extends Component {
  state = {
    treeData: [],
    loading: false,
  };

  fields = ["groupIdentifier", "memberGroupIds"];

  async componentDidMount() {
    this.setState({ loading: true });
    const id = this.props.match.params.id;
    const groupResponse = await this.props.selectGroupParentForTreeView(
      id,
      this.fields
    );
    const parentResponses = groupResponse.payload.data;
    if (parentResponses.length > 0) {
      this.setState({ treeData: [parentResponses[0]] });
    }
    this.setState({ loading: false });
  }

  onLoadData = (treeNode) =>
    new Promise((resolve, reject) => {
      const groupId = treeNode.props.eventKey;
      this.props.getGroupMembersForTreeView(groupId, this.fields).then(
        (res) => {
          if (res.payload.data) {
            treeNode.props.dataRef.children = res.payload.data;
            this.setState({
              treeData: [...this.state.treeData],
            });
          }
          resolve();
        },
        (err) => {
          reject(err);
        }
      );
    });

  navigateToGroup = (selection) => {
    if (selection && selection.length > 0) {
      this.props.history.push(`/group/${selection[0]}/details`);
    }
  };

  renderTreeNodes = (data, root = false) =>
    data.map((item) => {
      const isNotLeaf = item.memberGroupIds && item.memberGroupIds.length > 0;
      let icon = <></>;
      if (root) {
        icon = <BranchesOutlined />;
      }
      if (item.children) {
        return (
          <TreeNode
            icon={icon}
            title={item.groupIdentifier}
            isLeaf={!isNotLeaf}
            key={item.id}
            dataRef={item}
          >
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          icon={icon}
          title={item.groupIdentifier}
          isLeaf={!isNotLeaf}
          key={item.id}
          dataRef={item}
        />
      );
    });

  render() {
    const { treeData, loading } = this.state;
    return (
      <>
        <Segment color="blue">
          <LoadingWrapper loading={loading}>
            <Header size="medium">
              Group tree data{" "}
              <TooltipInfoIcon
                title={
                  "Groups that contain other groups can be expanded by clicking."
                }
              />
            </Header>
          </LoadingWrapper>
          <Tree
            className={styles.overflowControl}
            onSelect={this.navigateToGroup}
            showIcon={true}
            loading={loading}
            loadData={this.onLoadData}
          >
            {this.renderTreeNodes(treeData, true)}
          </Tree>
        </Segment>
      </>
    );
  }
}

export default connect((state) => ({}), {
  getGroupMembersForTreeView,
  selectGroupParentForTreeView,
})(GroupTreeView);
